* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif !important;
}

.headerBg {
  background: #f0eff9;
}

.swal-wide {
  width: 500px !important;
}

.fbBg {
  background: #4267b2 !important;
  margin-right: 1rem;
}

.GglBg {
  background: #34a853 !important;
}

.primaryBg {
  background: #f54838 !important;
}

.bgSuccess {
  background: #00711e !important;
}

.darkBg {
  background: #3d3d3d;
}

.lightGreyBg {
  background: #fafafa;
}

.ms-8 {
  margin-left: 80px;
}

.borderBottom {
  border-bottom: 1px solid #c1c1c1;
}

.borderTop {
  border-top: 1px solid #c1c1c1;
  padding-top: 10px;
}

.w-70 {
  width: 70% !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.mt-10 {
  margin-top: 6rem !important;
}

.fontBold h4 {
  font-weight: bold !important;
}

.w-55 {
  width: 55% !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: transparent;
}

/***** Registration Header ******/
.rhSection {
  width: 100%;
  position: relative;
}

.rhSection .rhBackground {
  width: 100%;
  height: 100%;
  position: relative;
}

.rhLogo {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.rhLogo .ant-image {
  top: 5rem;
  /* left: 44%; */
}

.hmLogo {
  cursor: pointer;
}

.custom-navbar {
  border-bottom: 1px solid #a8abb7;
  background: #fff !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1200;
}

.custom-navbar .navbar-collapse {
  justify-content: end;
}

.custom-navbar .nav-link {
  color: #3d3d3d !important;
}

.rounded-circle {
  cursor: pointer;
}

/***** Sidebar *****/
.siderLayout {
  position: fixed;
  width: 246px;
  height: calc(100% - 190px);
  background: #0f054a;
  box-shadow: 0 1px 3px 0 rgba(54, 74, 99, 0.05);
  top: 118px;
  border-radius: 35px;
  padding: 25px 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  transform: scale(1, 1);
  transform-origin: left;
  overflow: visible;
  margin-top: 0.5rem;
  min-height: 500px;
}

.siderLayout .ant-menu-root {
  padding-left: 0;
  margin: 0;
  z-index: 99;
  height: 63vmin;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
}

.siderLayout ul {
  background: #10054b !important;
}

.siderLayout ul li {
  color: #fff;
  list-style: none;
  /* padding: 0 40px !important; */
  margin: 5px 0 !important;
  margin-bottom: 25px !important;
  background: #10054b !important;
  z-index: 9;
}

.siderLayout ul li .ant-image {
  margin-right: 10px;
}

.siderLayout ul li.ant-menu-item-selected .ant-menu-title-content {
  color: #fff;
  font-weight: bold;
}

.siderLayout ul li.ant-menu-item-selected:after {
  left: 0;
  right: auto;
  border-right: 4px solid #f54838;
}

.siderLayout .ant-menu-inline,
.siderLayout .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: transparent !important;
}

.siderLayout .ant-menu-submenu .ant-menu-submenu-title {
  /* padding-left: 0 !important; */
  padding-right: 0 !important;
}

.siderLayout .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow {
  color: #fff !important;
}

.siderLayout .ant-menu {
  border-right: 0;
}

.siderLayout ul .ant-menu-submenu .ant-menu-sub .ant-menu-item {
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 60px !important;
}

.siderLayout ul .ant-menu-item:hover,
.siderLayout ul .ant-menu-submenu .ant-menu-submenu-title:hover {
  color: #fff !important;
}

.siderLayout .ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 30px;
  line-height: 30px;
}

.supportBtnSec {
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 25px;
  z-index: 999;
  background: #0f054a;
}

.supportBtnSec .supportBtn {
  padding: 0 25px;
}

.supportBtnSec .ant-image img {
  z-index: -9;
  display: inline;
}

.supportBtnSec .supportBtn .sprtBtn {
  all: unset;
  border: 2px solid #f54838;
  width: auto;
  border-radius: 50px;
  padding: 10px 35px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.supportBtnSec .supportBtn .sprtBtn:hover {
  background: #f54838;
}

.bodyContent {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 8rem;
  padding-bottom: 50px;
  /* margin-top: 3rem; */
}

.contentArea {
  width: auto;
  /* margin-top: 35px; */
  /* width: 76%; */
  margin-left: 310px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/***** Card *****/
.tnCard {
  border-radius: 35px !important;
  border: 0 !important;
  box-shadow: 0px 3px 50px 0px rgb(0 0 0 /8%);
  -webkit-box-shadow: 0px 3px 50px 0px rgb(0 0 0 /8%);
  -moz-box-shadow: 0px 3px 50px 0px rgb(0 0 0 /8%);
}

.tnCard .ant-card-body {
  padding: 40px;
}

.socialLogInBtn {
  height: auto !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 13px !important;
  border: 0 !important;
  border-radius: 35px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  line-height: 10px !important;
  padding: 15px !important;
  width: 4rem !important;
  text-align: center !important;
}

.tnBtnSec a.ant-btn {
  padding: 20px !important;
}

.customIcon {
  width: 2.3rem;
}

.customMarginIcon {
  margin-left: 1rem;
}

.tnBtnSec a.ant-btn i {
  padding-left: 12px;
}

.primaryBtn {
  height: auto !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 9px !important;
  border: 0 !important;
  border-radius: 35px !important;
  /* display: flex !important;
  justify-content: center !important;
  align-items: center !important; */
}

.tnBtnSec {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pdCstm {
  padding: 13px 40px !important;
}

.tnBtnSec .ant-image {
  margin-right: 15px;
}

.tnBtnSec i {
  font-size: 22px;
  margin-right: 15px;
}

.tnBtnSec .ant-radio-checked .ant-radio-inner,
.tnBtnSec .ant-radio-wrapper:hover .ant-radio,
.tnBtnSec .ant-radio:hover .ant-radio-inner,
.tnBtnSec .ant-radio-input:focus + .ant-radio-inner {
  border-color: #f54838 !important;
}

.tnBtnSec .ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgb(245 72 56 / 8%);
}

.tnBtnSec .ant-radio-checked .ant-radio-inner::after {
  background-color: #f54838;
}

.tnBtnSec .ant-radio-checked::after {
  border: 1px solid #f54838;
}

.tnBtnSec .ant-radio-wrapper {
  color: #3d3d3d;
  font-weight: 500;
  font-family: "Montserrat", sans-serif !important;
}

.orText {
  font-size: 20px;
  text-transform: uppercase;
  color: #3d3d3d !important;
  font-weight: 500;
}

.orTextBtm {
  text-transform: none;
}

.orSubText {
  text-transform: none;
  width: 58%;
  font-size: 16px;
  margin: 0 auto;
}

/***** SignUp Form *****/
.signUpCardSection {
  margin-top: -10rem;
  padding-bottom: 3rem;
}

.formControl,
.formControl textarea {
  background: #f2f2f2 !important;
  border-radius: 15px !important;
  border: 0 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

textarea.ant-input.formControl,
.formControl textarea {
  height: auto !important;
  resize: none !important;
}

.customSelect .ant-select-selector {
  background: #f2f2f2 !important;
  border-radius: 15px !important;
  border: 0 !important;
  font-size: 16px;
}

.customSelect:active {
  border: none !important;
}

.customLineHeight .ant-select-selector .ant-select-selection-item {
  line-height: inherit !important;
}

.customSelect .ant-select-selection-search-input {
  height: 35px !important;
}

.customSelect .ant-select-selection-placeholder {
  line-height: auto !important;
}

.ant-input-password.formControl input {
  background: #f2f2f2 !important;
}

.signUpForm .ant-form-item {
  margin-bottom: 15px;
}

.signUpForm .ant-form-item-label > label {
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px;
  font-weight: 500;
  color: #3d3d3d !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.formLink,
.formBtm {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif !important;
}

.primary-text {
  color: #0d6efd !important;
  padding-left: 0 !important;
}

.files .formLink {
  margin-bottom: 0 !important;
  font-weight: 600 !important;
}

/***** Top Section *****/
.topSection {
  width: 100%;
  height: 100%;
  position: relative;
}

.topLeftSection .tlsInner .logoSec {
  padding: 25px 0px;
}

.topLeftSection .tlsInner .leftHeading {
  margin-right: 80px;
}

.topLeftSection .tlsInner .leftHeading h2 {
  /* all: unset; */
  font-size: 36px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif !important;
  line-height: 48px;
  margin-bottom: 0;
  color: #3d3d3d !important;
}

.topLeftSection .tlsInner .leftHeading p {
  /* all: unset; */
  font-size: 18px;
  font-family: "DM Sans", sans-serif !important;
  line-height: 36px;
  margin-bottom: 0;
  color: #3d3d3d !important;
}

.tlsSection .tlsInput {
  width: 270px;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  padding: 20px;
  border-radius: 6px;
  font-family: "DM Sans", sans-serif !important;
  margin-right: 5px;
  font-weight: 600;
}

.tlsSection .tlsBtn {
  all: unset;
  padding: 20px 5px;
  background: #f54838;
  border: 1px solid #f54838;
  color: #fff;
  font-family: "DM Sans", sans-serif !important;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;
}

/***** Timeline Section *****/
.tlDivider {
  width: 1px;
  height: 100%;
  background: #f54838;
  margin: 0 auto;
}

.tmlSection .tlContent h5 {
  font-size: 22px;
  font-family: "DM Sans", sans-serif !important;
  color: #3d3d3d;
  line-height: 30px;
  font-weight: 500;
}

.tmlSection .tlContent p {
  font-size: 20px;
  font-family: "DM Sans", sans-serif !important;
  color: #787878;
  line-height: 34px;
}

.tmlSection .tlContent ul li {
  font-size: 20px;
  font-family: "DM Sans", sans-serif !important;
  color: #787878;
  line-height: 34px;
}

.tlc1:before {
  position: absolute;
  content: "1";
  width: 40px;
  height: 40px;
  left: -8rem;
  background: #fff;
  font-size: 20px;
  font-family: "DM Sans", sans-serif !important;
  color: #3d3d3d;
  box-shadow: 0px 0px 66px 0px rgb(0 0 0 /20%);
  -webkit-box-shadow: 0px 0px 66px 0px rgb(0 0 0 /20%);
  -moz-box-shadow: 0px 0px 66px 0px rgb(0 0 0 /20%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.tlc2:before {
  position: absolute;
  content: "2";
  width: 40px;
  height: 40px;
  right: -8rem;
  background: #fff;
  font-size: 20px;
  font-family: "DM Sans", sans-serif !important;
  color: #3d3d3d;
  box-shadow: 0px 0px 66px 0px rgb(0 0 0 /20%);
  -webkit-box-shadow: 0px 0px 66px 0px rgb(0 0 0 /20%);
  -moz-box-shadow: 0px 0px 66px 0px rgb(0 0 0 /20%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 9;
}

.tlc3:before {
  position: absolute;
  content: "3";
  width: 40px;
  height: 40px;
  left: -8rem;
  background: #fff;
  font-size: 20px;
  font-family: "DM Sans", sans-serif !important;
  color: #3d3d3d;
  box-shadow: 0px 0px 66px 0px rgb(0 0 0 /20%);
  -webkit-box-shadow: 0px 0px 66px 0px rgb(0 0 0 /20%);
  -moz-box-shadow: 0px 0px 66px 0px rgb(0 0 0 /20%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.posRltv img {
  position: relative;
  top: 4rem;
}

.psrltvBtm img {
  position: relative;
  bottom: 4rem;
}

/****** Why Choose Section *****/
.wcnSection {
  width: 100%;
  height: 100%;
  position: relative;
}

.wcnSection .wcnInner {
  padding: 100px 0;
}

.wcnSection .wcnInner .wcnHeading h2 {
  font-size: 40px;
  font-weight: bold;
  color: #3d3d3d;
  text-align: center;
  margin-bottom: 20px;
  font-family: "DM Sans", sans-serif !important;
  line-height: 53px;
  letter-spacing: -2px;
}

.wcnSection .wcnInner .wcnHeading h4 {
  width: 75%;
  font-size: 22px;
  font-weight: 200;
  color: #7d7d7d;
  text-align: center;
  margin: 0 auto;
  font-family: "DM Sans", sans-serif !important;
  line-height: 30px;
}

.color78 {
  color: #787878 !important;
}

.wcCard {
  height: 100%;
  border-radius: 8px !important;
  box-shadow: 0px 0px 77px 0px rgb(0 0 0 /6%);
  -webkit-box-shadow: 0px 0px 77px 0px rgb(0 0 0 /6%);
  -moz-box-shadow: 0px 0px 77px 0px rgb(0 0 0 /6%);
}

.wcCard .ant-card-body {
  padding: 30px;
}

.wcCard .wcCardIcon .wcCardIconInner {
  width: 50px;
  height: 50px;
  background: #f54838;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.wcCard .wcCardTitle h5 {
  font-size: 22px;
  font-family: "DM Sans", sans-serif !important;
  color: #3d3d3d;
  font-weight: 200;
  margin-bottom: 0;
}

.wcCard .wcCardDesc p {
  font-size: 16px;
  font-family: "DM Sans", sans-serif !important;
  color: #787878;
  font-weight: 200;
  margin-bottom: 0;
}

/***** Feature Section *****/
.ftrSection {
  width: 100%;
  height: 100%;
  position: relative;
  background: #f54838;
  margin: 50px 0;
}

.ftrContent {
  padding: 100px 0;
  position: relative;
}

.ftrContent .ftrHeading h2 {
  font-size: 40px;
  font-weight: bold;
  font-family: "DM Sans", sans-serif !important;
  color: #fff;
}

.ftrContentList ul {
  padding-left: 0;
}

.ftrContentList ul:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 48.4%;
  top: 20.5%;
  border: 1px solid #f2f2f2;
  border-right: 0;
}

.ftrContentList ul li {
  position: relative;
  list-style-type: none;
  padding-left: 30px;
  padding-bottom: 30px;
  /* border-left: 1px solid #F2F2F2; */
}

.ftrContentList ul li:last-child {
  border-left: 0;
}

.ftrContentList ul li h5 {
  font-size: 22px;
  color: #f2f2f2;
  line-height: 30px;
  font-family: "DM Sans", sans-serif !important;
  font-weight: 500;
  margin-bottom: 0;
}

.ftrContentList ul li p {
  font-size: 18px;
  color: #f2f2f2;
  line-height: 35px;
  font-family: "DM Sans", sans-serif !important;
  font-weight: 200;
  opacity: 0.81;
}

.ftrImage {
  position: absolute;
  z-index: 99;
  top: -15px;
  left: -49rem;
}

.ftrImage .ant-image img {
  width: 83%;
}

.ftrL1:before,
.ftrL2:before,
.ftrL3:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 8px;
  left: 10px;
  background: #f2f2f2;
}

.ftr2:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 1px;
  top: 12px;
  left: 0px;
  background: #f2f2f2;
}

/****** FAQ Section *****/
.faqSection {
  width: 100%;
  height: 100%;
  position: relative;
}

.faqSection .faqInner {
  padding: 80px 0;
}

.faqSection .faqInner .faqHeading h2 {
  font-size: 40px;
  font-weight: bold;
  color: #3d3d3d;
  text-align: center;
  margin-bottom: 20px;
  font-family: "DM Sans", sans-serif !important;
  line-height: 53px;
  letter-spacing: -2px;
}

.faqSection .faqInner .faqHeading h4 {
  width: 75%;
  font-size: 22px;
  font-weight: 200;
  color: #787878;
  text-align: center;
  margin: 0 auto;
  font-family: "DM Sans", sans-serif !important;
  line-height: 30px;
}

.faqSection .faqInner .faqcollapseSection .ant-collapse-header {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 600;
  font-family: "DM Sans", sans-serif !important;
  line-height: 30px;
  padding: 12px 0 5px !important;
}

.faqSection .faqInner .faqcollapseSection .ant-collapse {
  border: 0;
}

.faqSection .faqInner .faqcollapseSection .ant-collapse .ant-collapse-item {
  border-bottom: 1px solid #707070;
  margin-bottom: 10px;
}

.faqSection .faqInner .faqcollapseSection .ant-collapse-content {
  border: 0;
  background: transparent !important;
}

.faqSection
  .faqInner
  .faqcollapseSection
  .ant-collapse-content
  .ant-collapse-content-box
  p {
  font-size: 16px;
  font-weight: 500;
  color: #3d3d3d;
  font-family: "DM Sans", sans-serif !important;
  margin-bottom: 0;
}

.faqSection .faqInner .faqcollapseSection .anticon-plus {
  font-size: 20px !important;
  color: #f54838;
}

/***** NewsLetter Section *****/
.newsLetterSection {
  width: 100%;
  height: 100%;
  position: relative;
}

.newsLetterSection .nlsInner {
  padding: 70px 0;
}

.newsLetterSection .nlsInner .nlsHeading h2 {
  font-size: 35px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: 0;
  font-family: "DM Sans", sans-serif !important;
  line-height: 55px;
}

.nlsInputSection .nlsInput {
  width: 423px;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  padding: 20px;
  border-radius: 6px;
  font-family: "DM Sans", sans-serif !important;
  margin-right: 10px;
  font-weight: 600;
}

.nlsInputSection .nlsBtn {
  all: unset;
  padding: 20px 30px;
  background: #f54838;
  border: 1px solid #f54838;
  color: #fff;
  font-family: "DM Sans", sans-serif !important;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
}

/***** Create Profile *****/
.customUpload .ant-upload.ant-upload-select-picture-card {
  width: 109px;
  height: 109px;
  border: 0;
  border-radius: 50%;
  background-color: #f2f2f2;
}

.card2 {
  width: 130px !important;
  height: 70px !important;
}

.customLabel h4 {
  font-size: 16px !important;
  color: #3d3d3d !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 800 !important;
  margin-bottom: 0px !important;
}

.customLabel p {
  font-size: 11px;
  color: #3d3d3d;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
}

.noteRow {
  margin: 10px 0;
  font-weight: 500;
}

.noteRow .anticon-exclamation-circle {
  color: #e6a01e;
  margin-right: 10px;
  vertical-align: unset;
}

.ant-checkbox-inner {
  border-radius: 5px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f54838 !important;
  border-color: #f54838 !important;
  border-radius: 5px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #f54838 !important;
}

.customMultiSelect .ant-select-selector {
  padding: 15px !important;
  background: #f2f2f2 !important;
  border-radius: 15px !important;
  height: auto !important;
  border: 0 !important;
  font-size: 16px;
}

.customMultiSelect .ant-select-selection-item {
  background: #e6e6e6 !important;
  border-radius: 50px !important;
  height: auto;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  padding-right: 0 !important;
  width: auto !important;
  flex: 0 0 auto !important;
  font-size: 14px;
  padding: 0 15px !important;
}

.customMultiSelect
  .ant-select-selection-item
  .ant-select-selection-item-remove {
  font-size: 12px;
  line-height: 15px;
}

.prflTitle {
  font-size: 1.5rem;
  color: #3d3d3d;
  font-family: "Montserrat", sans-serif !important;
  text-align: center;
  font-weight: 500;
}

.profileCard {
  width: 100%;
  height: 100%;
  position: relative;
}

.profileCard .cardProfile {
  width: 100%;
  height: 100;
  background: #f2f2f2;
  border-radius: 35px;
}

.profileCard .cardProfile .ant-card-body {
  padding: 8px 24px;
}

.profileCard .welcomeTextSec h2 {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: bold;
}

.profileCard .welcomeTextSec p {
  font-size: 28px;
  color: #3d3d3d;
  font-weight: 500;
}

.dashboardCard {
  width: 100%;
  height: 100%;
  position: relative;
}

.dashboardCard .dashCard {
  width: 100%;
  height: 100%;
  border-radius: 35px;
}

.dashboardCard .dashCard .ant-card-body {
  padding: 20px;
  /* padding: 24px 30px */
}

.dashboardCard .cardBg1 {
  background: #9563e7;
}

.dashboardCard .cardBg2 {
  background: #f44938;
}

.dashboardCard .cardBg3 {
  background: #0f054a;
}

.dashboardCard .dashCard h2 {
  font-size: 22px;
  color: #fff;
  margin-bottom: 0.2em;
}

.dashboardCard .dashCard p {
  font-size: 14px;
  color: #fff;
  margin-bottom: 0.2em;
}

.dashboardCard .dashCard .dashCardCount {
  font-size: 28px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
}

.jobRqst {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #c1c1c1;
  padding: 25px 0;
}

.colFirst,
.colSecond,
.colThird,
.colFirst .prflSec {
  display: flex;
  align-items: center;
}

.colFirst .prflSec h4 {
  font-size: 16px;
  color: #3d3d3d;
  margin-bottom: 0;
  margin-left: 15px;
}

.colSecond .detailsSec p,
.colThird .periodSec p {
  margin-bottom: 0.2em;
  font-size: 15px;
}

.colSecond .detailsSec .designation {
  color: #a8abb7;
}

.colSecond .detailsSec .company {
  color: #3d3d3d;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.colSecond .detailsSec .exp {
  color: #3d3d3d;
  font-weight: 400;
  text-transform: uppercase;
}

.colThird .periodSec p > span {
  color: #3d3d3d;
  font-weight: 600;
  margin-right: 15px;
}

.rejectBtn {
  border: 1px solid #f54838 !important;
  border-radius: 50px !important;
  background: #f2f2f2 !important;
  font-weight: 600 !important;
  color: #3d3d3d !important;
  height: 48px !important;
  padding: 10px 25px !important;
  margin-right: 5px !important;
}

.acceptBtn {
  border: 1px solid #f54838 !important;
  border-radius: 50px !important;
  background: #f54838 !important;
  font-weight: 600 !important;
  color: #fff !important;
  height: 48px !important;
  padding: 10px 30px !important;
  margin-right: 5px !important;
  transition: all 0.3s ease-in-out;
}

.acceptBtn:hover {
  background: transparent !important;
  color: #f54838 !important;
}

.ant-badge .ant-badge-dot {
  width: 10px;
  min-width: 10px;
  height: 10px;
  background: green;
  top: 1.5rem;
}

/***** DataTable *****/
.tnDataTable .ant-table-thead > tr > th {
  background: transparent !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #a8abb7 !important;
  border-top: 1px solid #c1c1c1 !important;
  border-bottom: 1px solid #c1c1c1 !important;
  text-align: left;
}
.tnDataTable .ant-table-thead > tr > th:before {
  display: none !important;
}

.jobRqstList table thead th {
  display: none !important;
}

.titleBtmBrdr {
  border-bottom: 1px solid #c1c1c1;
}

/***** Packages *****/
.dFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
}

.mediaImg {
  flex: 0 0 50%;
}

.jobPackages {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.jobPackages .jobID p,
.jobPackages .jobStart p,
.jobPackages .jobDue p {
  font-size: 16px;
  color: #3d3d3d !important;
  margin-bottom: 0;
  font-weight: 500;
}

.media {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.titlePdng .titlePd {
  padding-bottom: 10px;
  font-weight: 500;
}

.cancel-reasons {
  font-weight: 500;
}

.custom-cancel-btn {
  padding: 8px 90px !important;
}

/* .media:hover {
  =======
  .pckgCard:hover {
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.15);
  }
  .me-3 .ant-image {
  width: 306px;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .pcCard {
  display: flex;
  flex-direction: column;
  }
  .pcCard .ant-rate-text {
  margin: 0;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #3D3D3D !important
  }
  .pcCard .ant-rate-text .greyText{
  font-weight: 500 !important;
  color: #A8ABB7 !important
  }
  .contactBtn {
  border: 1px solid #C1C1C1 !important;
  border-radius: 50px !important;
  background: transparent !important;
  font-weight: 600 !important;
  color: #C1C1C1 !important;
  height: 48px !important;
  padding: 10px 25px !important;
  margin-right: 5px !important;
  transition: all 0.3s ease-in-out
  }
  .contactBtn:hover {
  border: 1px solid #C1C1C1 !important;
  background: #C1C1C1 !important;
  color:  #fff !important;
  }
  .continueBtn {
  border: 1px solid #C1C1C1 !important;
  border-radius: 50px !important;
  background: #C1C1C1 !important;
  font-weight: 600 !important;
  color: #fff !important;
  height: 48px !important;
  padding: 10px 25px !important;
  margin-right: 5px !important;
  transition: all 0.3s ease-in-out
  }
  .continueBtn:hover {
  border: 1px solid #C1C1C1 !important;
  background: transparent !important;
  color:  #C1C1C1 !important;
  }
  .greyText {
  font-weight: 500 !important;
  color: #A8ABB7 !important;
  font-size: 12px;
  }
  .aboutPackage p,
  .abtCntnList .abtCntnItem {
  font-size: 15px;
  color: #3D3D3D;
  font-weight: 500;
  position: relative;
  }
  .abtCntnList {
  list-style: none;
  padding-left: 0;
  }
  .abtCntnList .abtCntnItem {
  display: flex;
  align-items: center;
  margin: 15px 0;
  }
  .abtCntnList .abtCntnItem .star {
  width: 15px;
  height: 15px;
  background: green;
  color: #fff;
  border: 1px solid #3D3D3D;
  margin-bottom: 0;
  margin-right: 5px;
  text-align: center;
  line-height: 22px;
  font-size: 20px;
  }
  .customDrag .ant-upload.ant-upload-drag {
  background: #F2F2F2 !important;
  height: 300px;
  border-radius: 15px;
  }
  .customDrag .ant-upload.ant-upload-drag .ant-upload-btn {
  padding: 25px
  }
  .customDrag .ant-upload.ant-upload-drag .ant-upload-btn .anticon {
  color: #C1C1C1;
  }
  .customDrag .ant-upload.ant-upload-drag .ant-upload-btn .ant-upload-text {
  color: #3D3D3D;
  font-size: 18px !important;
  font-weight: bold !important;
  }
  .customDrag .ant-upload.ant-upload-drag .ant-upload-btn .ant-upload-hint {
  color: #3D3D3D;
  font-size: 20px;
  }
  .cstmUpload .ant-upload-list-picture-card {
  display: flex;
  }
  .cstmUpload .ant-upload-list-picture-card .ant-upload-list-picture-card-container {
  order: 2
  }
  .cstmUpload .ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0;
  border: 0;
  }
  .cstmUpload .ant-upload-list-picture-card .ant-upload-list-item .ant-upload-list-item-done {
  order: 1
  }
  .cstmUpload .ant-upload-list-picture-card .ant-upload .anticon,
  .cstmUpload .ant-upload-select-picture-card .ant-upload .anticon {
  color: #3D3D3D;
  font-size: 26px;
  }
  .cstmUpload .ant-upload-list-picture-card .ant-upload .anticon + div,
  .cstmUpload .ant-upload-select-picture-card .ant-upload .anticon + div {
  display: none
  }
  .innerFC h5 {
  font-size: 14px !important;
  color: #3D3D3D !important
  }
  .innerFC .formcontrol {
  width: 40%;
  background: #F2F2F2 !important;
  border-color: #F2F2F2 !important;
  width:100px !important;
  }
  /* .innerFC .ant-select {
  width: 20% !important;
  } */
.innerFC .ant-select .ant-select-selector {
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
}

.innerFC .ant-select .ant-select-arrow {
  color: #000000 !important;
}

.addPackageForm p {
  font-size: 14px !important;
  font-weight: 500;
}

.btnPY {
  padding: 10px 45px !important;
}

.prflImg {
  position: relative;
}

.prflImg .ant-badge {
  width: 125px;
  height: 125px;
}

.prflImg .ant-badge .ant-badge-dot {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 80%;
  left: 60%;
}

.prflName {
  margin-left: 15px;
}

.prflName h4.ant-typography {
  all: unset;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 700;
  margin-bottom: 0;
}

.prflName p {
  font-size: 14px;
  color: #a8abb7;
}

.words {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 600;
  display: flex;
  margin-bottom: 0;
}

.formItemHead {
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 700;
  display: flex;
  margin-bottom: 10px;
}

.edtBtn {
  all: unset !important;
  padding: 0 !important;
}

.edtBtn .ant-image .ant-image-img {
  max-width: 400px !important;
  max-width: 350px !important;
}

.formItem {
  padding: 20px 0;
  border-bottom: 1px solid #c1c1c1;
}

.formItem .prflDesc {
  font-size: 16px;
  font-weight: 500;
  word-break: break-word;
  margin-bottom: 0;
}

.topTagsRows .tagsTop .ant-tag {
  background: #555092;
  border-color: #555092;
  color: #fff;
  font-weight: 500;
}

.topTagsRows .tagsTop .ant-tag .anticon-close {
  color: #555092;
}

.cnclBtn {
  color: #3d3d3d !important;
  font-weight: 700 !important;
  border-radius: 24px !important;
  width: 15% !important;
  height: 46px !important;
  background-color: #e8e8e8 !important;
  border: none !important;
  margin-right: 1rem !important;
}

.saveBtn {
  color: #fff !important;
  font-weight: 700 !important;
  background: #f54838 !important;
  border-radius: 24px !important;
  width: 15% !important;
  height: 46px !important;
  margin-top: 1rem;
  border: none;
}

.saveBtn:hover {
  border: 1px solid #f54838 !important;
  color: #f54838 !important;
  background-color: white !important;
}

.wAuto {
  width: auto !important;
}

.topTagsRows .ant-select-arrow {
  color: #000 !important;
}

.profileModal .ant-modal-content {
  border-radius: 15px;
}

.profileModal .ant-modal-content .ant-modal-body {
  padding: 30px 50px;
  margin-top: 25px;
}

.profileModal .ant-modal-content .ant-modal-body h4 {
  font-size: 22px;
  font-weight: bold;
}

.profileModal .profileModalBody .jobRqstHeading {
  border-bottom: 1px solid #c1c1c1;
}

.profileModal .ant-modal-content .ant-modal-body .customLabel h4 {
  font-size: 16px;
  font-weight: 500;
}

.profileModal .ant-modal-content .ant-modal-close {
  top: 2%;
  right: 6%;
}

.profileModal .ant-modal-content .ant-modal-close .ant-modal-close-x {
  font-size: 24px;
  font-weight: bold;
  color: #3d3d3d;
}

.HireModal .ant-modal-content {
  border-radius: 15px;
}

.HireModal .ant-modal-content .ant-modal-close {
  right: 1%;
}

.HireModal .ant-modal-content .ant-modal-close .ant-modal-close-x {
  font-size: 24px;
  font-weight: bold;
  color: #3d3d3d;
}

.HireModal .jobRqstHeading {
  border-bottom: 1px solid #f2f2f2;
}

.HireModal .ant-modal-content .ant-modal-body {
  padding: 25px;
}

.HireModal .packageCard {
  border-radius: 15px !important;
  height: 100%;
  margin-bottom: 1rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.HireModal .packageRelevent {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.HireModal .packageCard .pckgContent {
  height: 300px;
}

.HireModal .packageCard .hire-modal-pckg-content .ant-image img {
  height: 270px !important;
  width: 320px !important;
  border-radius: 15px;
}

.HireModal .packageCardBody {
  height: 100%;
}

.HireModal .packageCard .ant-card-body {
  padding: 15px;
  height: 100%;
}

.pckgCheck .ant-checkbox-inner {
  border-radius: 50% !important;
}

.brdrCard {
  border-radius: 15px !important;
  height: 100% !important;
}

.brdrCard p {
  font-weight: 500;
  color: #3d3d3d !important;
}

.brdrCard .ant-card-body {
  height: 100% !important;
  display: flex;
}

.brdrCard .ant-card-body .packageRelevent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100% !important;
}

.ant-checkbox-checked::after {
  border: 0 !important;
}

.chatMainBody {
  border: 1px solid #f2f2f2;
  overflow: hidden;
}

.chatSidebar {
  border-right: 1px solid #f2f2f2;
}

.chatSideHead,
.chatSearch,
.chatItem,
.termsSection {
  padding: 15px;
  border-bottom: 1px solid #f2f2f2;
}

.chatSideHead h4 {
  margin-bottom: 0 !important;
}

.chatSearchInput input,
.chatSearchInput .ant-input-search-button,
.chatMain .conversationSec {
  border: 0;
}

.chatSearchInput .ant-input-search-button {
  color: #3d3d3d !important;
}

.chatItem:last-child {
  border-bottom: 0;
}

.chatItem .nameSec h4 {
  font-size: 14px;
  font-weight: 500;
}

.chatItem .nameSec p,
.chatTime .greyText {
  font-size: 12px !important;
  margin-bottom: 0;
}

.chatItem {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.chatItem:hover,
.chatItem:f-child {
  background: #f2f2f2;
}

/**** Cards ****/
.customerCard {
  border-color: #c1c1c1 !important;
  border-radius: 15px !important;
}

.ccTtl {
  font-size: 18px !important;
  color: #3d3d3d !important;
}

.ccSubText {
  color: #3d3d3d !important;
  padding: 0 25px !important;
  font-weight: 500 !important;
  margin-bottom: 70px !important;
}

.ccTitle h2 {
  font-size: 24px !important;
  color: #00711e !important;
  margin-bottom: 30px !important;
}

.colorGrey h2 {
  color: #a8abb7 !important;
}

.cstmrCard h2 {
  margin-bottom: 0px !important;
}

.mutedText h2 {
  color: #a8abb7 !important;
}

.cancelText h2 {
  font-size: 28px !important;
  color: #f51500 !important;
  margin-bottom: 30px !important;
}

.nameSec {
  margin-left: 15px;
}

.nameSec h4 {
  margin-left: 0 !important;
}

.nameSec p {
  font-size: 16px !important;
  color: #a8abb7 !important;
}

.cancelBtn {
  all: unset !important;
  color: #f54838 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  cursor: pointer !important;
}

.priceDetails {
  margin: 15px 0;
}

.priceDetails:last-child {
  margin-bottom: 0 !important;
}

.priceDetails span {
  margin-bottom: 0 !important;
  font-size: 14px !important;
  color: #3d3d3d !important;
  font-weight: 500;
}

/***** Tabs *****/
.jobTabs .ant-tabs-nav:before {
  border-color: #c1c1c1 !important;
  bottom: -10px !important;
}

.jobTabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  color: #a8abb7 !important;
  font-size: 16px !important;
  font-weight: 500;
}

.jobTabs
  .ant-tabs-nav
  .ant-tabs-nav-list
  .ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #3d3d3d !important;
}

.jobTabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 60px;
}

.jobTabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar {
  background: #f54838 !important;
}

.pckgHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pckgHeader h4 {
  font-size: 16px !important;
  color: #a8abb7 !important;
}

.pckgHeader h2 {
  font-size: 22px !important;
  color: #3d3d3d;
  margin-top: 0 !important;
  font-weight: 300;
}

.secondaryBg {
  background: #f51500 !important;
}

.cancelSubText {
  color: #3d3d3d !important;
  font-weight: 500;
}

.priceSec p {
  font-size: 22px !important;
  font-weight: 700 !important;
  color: #3d3d3d !important;
}

.ant-rate-text {
  font-size: 24px !important;
  color: #3d3d3d !important;
}

/***** Conversation *****/
.conversationSec {
  background: #fff;
  border: 1px solid #f2f2f2;
}

.conversationInner .conversationBox {
  /* padding: 10px; */
  max-height: 100%;
  height: 40vh;
  overflow-y: auto;
}

.conversationInner .customConversation {
  padding: 10px;
  max-height: 100%;
  height: 40vh;
  overflow-y: auto;
}

.conversationBox .ant-comment-content-author {
  flex-direction: column;
}

.conversationBox .ant-comment-content-author-name {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #3d3d3d !important;
}

.conversationBox .ant-comment-content-author-time {
  font-size: 12px !important;
  color: #a8abb7 !important;
}

.conversationBox .ant-comment-content-detail {
  color: #3d3d3d !important;
  font-weight: 500 !important;
  margin-top: 10px;
}

.conversationBox .ant-comment-content-detail p {
  margin-bottom: 10px;
}

.conversationFooter {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  padding: 20px;
}

.ftrFormControl {
  margin-bottom: 0 !important;
}

.ftrFormControl
  .ant-form-item-control-input
  .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}

.cnvrsFtrInput {
  background: #fff;
  height: 55px;
  border-radius: 15px !important;
  border: 0 !important;
}

.cnvrsFtrBtn {
  background: #f54838 !important;
  border-color: #f54838 !important;
  border-radius: 18px !important;
  color: #fff !important;
  font-weight: 600 !important;
  margin-left: 10px;
}

.cnvrsFtrBtns {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  font-size: 16px !important;
  padding: 5px 10px !important;
  position: relative !important;
}

/***** Activities *****/
.rqurmntHeader {
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 10px;
}

.rqurmntHeader h4,
.tFootText {
  font-size: 18px !important;
  color: #3d3d3d !important;
  font-weight: 700 !important;
}

.rqurmntHeader span {
  font-size: 12px !important;
  color: #a8abb7 !important;
}

.rqurmntCntn {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

/* .rqurmntContentBox {
    margin-left: 10px;
  } */

.rqurmntCntn h5 {
  color: #3d3d3d !important;
  display: flex;
}

.rqurmntCntn p,
.rqurmntContentList p {
  color: #3d3d3d !important;
  font-weight: 500 !important;
  padding-left: 2.5rem;
}

.rqurmntLi {
  font-weight: 700 !important;
}

.imgBox {
  position: relative;
  overflow: hidden;
  width: 150px;
  height: 150px;
  border: 1px solid #a8abb7;
  border-radius: 15px;
}

.imgBox .ant-image .ant-image-img {
  height: 125px !important;
}

.imgFooter {
  position: absolute;
  bottom: 0;
  background: #f2f2f2;
  width: 100%;
  padding: 5px !important;
  display: flex;
  justify-content: center;
}

.imgFooter a {
  color: black !important;
}

.imgFooter p {
  margin-bottom: 0;
  padding: 5px;
}

.imgFooter .downloadFile {
  cursor: pointer;
}

.imgFooter .anticon svg {
  vertical-align: inherit;
}

.activitiesResFtr {
  border-top: 1px solid #c1c1c1;
  padding-top: 10px;
}

.activitiesResFtr p {
  color: #a8abb7 !important;
  font-weight: 500 !important;
}

.textGreen h4 {
  color: #00711e !important;
}

.textPrimary h4 {
  color: #f51500 !important;
}

.customPriceCard .ant-card-body {
  padding: 0;
}

.customPriceCard table {
  margin-bottom: 0;
  table-layout: fixed;
}

.customPriceCard table tr td,
.customPriceCard table thead tr th {
  border-bottom: 0;
  border-right: 2px solid #c1c1c1;
  padding: 15px;
}

.customPriceCard table thead tr th {
  padding: 20px;
}

.customPriceCard table tr td:last-child,
.customPriceCard table thead tr th:last-child {
  border-right: 0;
  padding-right: 40px;
}

.customPriceCard .table > :not(:first-child) {
  border-top-color: #c1c1c1;
}

.customPriceCard table .anticon-check {
  color: #f54838 !important;
}

.talentCard {
  background: #fff !important;
  border-color: #c1c1c1 !important;
  border-radius: 22px !important;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.talentCard:hover {
  box-shadow: 0 3px 40px rgba(0, 0, 0, 0.1);
}

.leftPart .nameSec h4 {
  font-size: 16px !important;
  margin-bottom: 0px !important;
  text-transform: capitalize;
}

.leftPart .nameSec p {
  font-size: 12px !important;
  color: #a8abb7 !important;
}

.tcRate p {
  font-size: 16px !important;
  color: #3d3d3d !important;
  font-weight: 600 !important;
  margin-bottom: 0;
}

.likeIcon {
  border: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
  line-height: 0 !important;
  font-size: 20px !important;
  color: #8a8a8a !important;
  height: auto !important;
}

.tcTagsRow {
  margin-top: 20px;
}

.tcStar .starIcon {
  color: #f54838 !important;
}

.tcStar .rating {
  vertical-align: middle;
  font-size: 1rem !important;
  color: #727272 !important;
  font-weight: 500 !important;
}

.tcHeading h4 {
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-bottom: 0;
}

.tcHeading p {
  font-size: 14px !important;
  color: #3d3d3d !important;
  font-weight: 500 !important;
  overflow: hidden;
  word-break: break-word;
  margin-top: 1rem;
}

.tagCol h5 {
  font-size: 16px !important;
  color: #3d3d3d !important;
  font-weight: 500 !important;
}

.tcTags .ant-tag {
  font-size: 10px !important;
  color: #3d3d3d !important;
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
  border-radius: 15px !important;
  font-weight: 500 !important;
  margin-bottom: 0.5rem;
}

.seeBtn {
  background: #f54838 !important;
  border-color: #f54838 !important;
  border-radius: 25px !important;
  color: #fff !important;
  font-weight: 600 !important;
  width: 190px;
  height: auto !important;
  padding: 10px !important;
}
.seeBtn:hover {
  background: transparent !important;
  color: #f54838 !important;
}
.customSeeBtn {
  background: #f54838 !important;
  border-color: #f54838 !important;
  border-radius: 25px !important;
  color: #fff !important;
  font-weight: 600 !important;
  width: 150px;
  height: auto !important;
  padding: 10px !important;
}

.hireBtnMsg {
  color: #f54838 !important;
  border-color: #f54838 !important;
  background: #fff !important;
  transition: all 0.3s ease-in-out;
}

.hireBtnMsg:hover {
  color: #fff !important;
  background: #f54838 !important;
}

.hireBtnHr {
  background: #f54838 !important;
  border-color: #f54838 !important;
  transition: all 0.3s ease-in-out;
}

.hireBtnHr:hover {
  background: #fff !important;
  color: #f54838 !important;
}

.hireBtnAp {
  background: #00711e !important;
  border-color: #00711e !important;
  transition: all 0.3s ease-in-out;
}

.hireBtnAp:hover {
  background: #fff !important;
  color: #00711e !important;
}

.tcPageHeading p {
  color: #3d3d3d !important;
  font-weight: 500 !important;
}

.topTagsRow {
  display: flex;
  padding: 20px 0;
  align-items: center;
  flex-wrap: wrap;
}

.topTagsRow .tagText {
  color: #3d3d3d !important;
  font-weight: 600 !important;
  margin-right: 50px;
}

.tagText {
  margin-left: 0.3rem;
}

.tagsTop {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tagsTop .ant-tag {
  background: #555092 !important;
  border-color: #555092 !important;
  color: #fff;
  border-radius: 15px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.tagsTop .ant-tag .anticon-close {
  color: #fff;
}

.filtersOption .ant-select {
  margin-right: 10px;
}

.talentPackages .filtersOption .ant-select:last-child {
  margin-right: 0px;
  position: absolute;
  width: 100px !important;
}

.filtersOption .filtersSlct .ant-select-selector {
  border-radius: 15px !important;
  width: 230px !important;
  height: 53px !important;
  padding: 0 2px !important;
}

.wAuto .filtersSlct .ant-select-selector {
  width: 153px !important;
}

.filtersOption .w-100 .ant-select-selector {
  width: 100% !important;
}

.filtersOption .filtersSlct .ant-select-selector .ant-select-selection-item {
  line-height: 53px !important;
  padding-left: 5px;
}

.filtersOption .filtersSlct .ant-select-arrow {
  color: #3d3d3d !important;
  font-size: 13px !important;
  /* right: 30px !important; */
}

.filterDropdown .filtersSlct .ant-select-selector {
  width: 100% !important;
  border-radius: 5px !important;
}

.primaryColor {
  color: #f54838 !important;
}

.infoIcon {
  color: #e6a01e !important;
}

.notesRow {
  color: #c1c1c1;
  font-size: 12px !important;
}

.divider {
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  margin: 30px 0;
}

.dividernew {
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  margin: 6px 0;
}

.description,
.darkText,
.reviewItems .ant-comment-content-author-name,
.reviewItems .ant-rate-text,
.reviewText,
.methodName,
.methodDescription {
  font-size: 16px !important;
  color: #3d3d3d !important;
  font-weight: 500 !important;
}

.methodName {
  margin-bottom: 0;
}

.methodDescription {
  font-weight: 400 !important;
  margin-bottom: 0;
}

.dlvrytext {
  color: #a8abb7 !important;
}

.dlvrytext .darkText {
  font-weight: 600 !important;
}

.totalEarnings h5,
.sendToYou h5,
.Pending h5,
.Available h5 {
  color: #10054b !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

.sendToYou h5,
.Pending h5 {
  color: #3d3d3d !important;
}

.totalEarnings p {
  color: #10054b !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.Pending p,
.sendToYou p,
.Available p {
  color: #3d3d3d;
  font-size: 16px;
  font-weight: 500;
}

.Available h5,
.Available p {
  color: #00711e !important;
}

.earnNotes,
.subTitle {
  font-size: 14px !important;
  color: #3d3d3d;
  font-weight: 500 !important;
}

.highLight {
  color: #fcb414 !important;
}

.subTitle {
  width: 35%;
}

.cardFormControl,
.cardFormControl .ant-select-selector {
  background: #f2f2f2 !important;
  border: 0 !important;
  border-bottom: 1px solid #aaaaaa !important;
  height: 40px !important;
  line-height: 40px !important;
}

.cardFormControl .ant-select-selector .ant-select-selection-search-input {
  height: 40px !important;
}

.cardFormControl {
  width: 100%;
}

.cardFormControl input {
  background: #f2f2f2 !important;
}

.addCard label {
  font-size: 16px !important;
  color: #3d3d3d !important;
  font-weight: 500 !important;
}

.paymentBtn {
  background: #f54838 !important;
  border-color: #f54838 !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-weight: 600 !important;
  height: auto !important;
  padding: 10px 30px !important;
}

.ant-picker-input::placeholder {
  content: "MM" !important;
}

.addCard .ant-picker-suffix,
.addCard .ant-select-arrow {
  color: #000000 !important;
}

.pckgCard {
  border-color: #c1c1c1 !important;
  border-radius: 15px !important;
  margin-bottom: 25px !important;
  cursor: pointer !important;
  transition: all 0.3s ease-in-out !important;
}

.pckgOther .pckgCard {
  background: #f2f2f2 !important;
}

.pckgCard:hover {
  box-shadow: 0px 3px 40px rgba(0, 0, 0, 0.15);
}

.me-3 .ant-image {
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.pcCard {
  display: flex;
  flex-direction: column;
}

.pcCard .ant-rate-text {
  margin: 0;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #3d3d3d !important;
}

.pcCard .ant-rate-text .greyText {
  font-weight: 500 !important;
  color: #a8abb7 !important;
}

.contactBtn {
  border: 1px solid #c1c1c1 !important;
  border-radius: 50px !important;
  background: transparent !important;
  font-weight: 600 !important;
  color: #c1c1c1 !important;
  height: 48px !important;
  padding: 10px 25px !important;
  margin-right: 5px !important;
  transition: all 0.3s ease-in-out;
}

.contactBtn:hover {
  border: 1px solid #c1c1c1 !important;
  background: #c1c1c1 !important;
  color: #fff !important;
}

.continueBtn {
  border: 1px solid #c1c1c1 !important;
  border-radius: 50px !important;
  background: #c1c1c1 !important;
  font-weight: 600 !important;
  color: #fff !important;
  height: 48px !important;
  padding: 10px 25px !important;
  margin-right: 5px !important;
  transition: all 0.3s ease-in-out;
}

.continueBtn:hover {
  border: 1px solid #c1c1c1 !important;
  background: transparent !important;
  color: #c1c1c1 !important;
}

.greyText {
  font-weight: 500 !important;
  color: #a8abb7 !important;
  font-size: 12px;
}

.aboutPackage p,
.abtCntnList .abtCntnItem {
  font-size: 15px;
  color: #3d3d3d;
  font-weight: 500;
  position: relative;
}

.abtCntnList {
  list-style: none;
  padding-left: 0;
}

.abtCntnList .abtCntnItem {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.abtCntnList .abtCntnItem .star {
  width: 15px;
  height: 15px;
  background: green;
  color: #fff;
  border: 1px solid #3d3d3d;
  margin-bottom: 0;
  margin-right: 5px;
  text-align: center;
  line-height: 22px;
  font-size: 20px;
}

.customDrag .ant-upload.ant-upload-drag {
  background: #f2f2f2 !important;
  height: 300px;
  border-radius: 15px;
}

.customDrag .ant-upload.ant-upload-drag .ant-upload-btn {
  padding: 25px;
}

.customDrag .ant-upload.ant-upload-drag .ant-upload-btn .anticon {
  color: #c1c1c1;
}

.customDrag .ant-upload.ant-upload-drag .ant-upload-btn .ant-upload-text {
  color: #3d3d3d;
  font-size: 18px !important;
  font-weight: bold !important;
}

.customDrag .ant-upload.ant-upload-drag .ant-upload-btn .ant-upload-hint {
  color: #3d3d3d;
  font-size: 20px;
}

.cstmUpload .ant-upload-list-picture-card {
  display: flex;
}

.cstmUpload
  .ant-upload-list-picture-card
  .ant-upload-list-picture-card-container {
  order: 2;
}

.cstmUpload .ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0;
  border: 0;
}

.cstmUpload
  .ant-upload-list-picture-card
  .ant-upload-list-item
  .ant-upload-list-item-done {
  order: 1;
}

.cstmUpload .ant-upload-list-picture-card .ant-upload .anticon,
.cstmUpload .ant-upload-select-picture-card .ant-upload .anticon {
  color: #3d3d3d;
  font-size: 26px;
}

.cstmUpload .ant-upload-list-picture-card .ant-upload .anticon + div,
.cstmUpload .ant-upload-select-picture-card .ant-upload .anticon + div {
  display: none;
}

.innerFC h5 {
  font-size: 14px !important;
  color: #3d3d3d !important;
}

.innerFC .formcontrol {
  width: 40%;
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
}

/* .innerFC .ant-select {
  width: 20% !important;

  } */
.innerFC .ant-select .ant-select-selector {
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
  width: 100px !important;
}

.custom-bottom-text {
  font-size: 14px !important;
  color: #a8abb7 !important;
}

.custom-bio-padding {
  padding: 4px 10px 0 10px;
}

.update-bio {
  margin-top: 15px;
  margin-bottom: 15px;
}

.innerFC .ant-select .ant-select-arrow {
  color: #000000 !important;
}

.addPackageForm p {
  font-size: 14px !important;
  font-weight: 500;
}

.btnPY {
  padding: 10px 45px !important;
}

.prflImg {
  position: relative;
}

.prflImg .ant-badge {
  width: 125px;
  height: 125px;
}

.prflImg .ant-badge .ant-badge-dot {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 80%;
  left: 60%;
}

.prflName {
  margin-left: 15px;
}

.prflName h4.ant-typography {
  all: unset;
  font-size: 20px;
  color: #3d3d3d;
  font-weight: 700;
  margin-bottom: 0;
}

.prflName p {
  font-size: 14px;
  color: #a8abb7;
}

.words {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 600;
  display: flex;
  margin-bottom: 0;
}

.formItemHead {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: 500;
  display: flex;
  margin-bottom: 10px;
}

.edtBtn {
  all: unset !important;
  padding: 0 !important;
}

.edtBtn .ant-image .ant-image-img {
  max-width: 400px !important;
  max-width: 350px !important;
}

.formItem {
  padding: 20px 0;
  border-bottom: 1px solid #c1c1c1;
}

.formItem .prflDesc {
  font-size: 16px;
  font-weight: 500;
  word-break: break-word;
  margin-bottom: 0;
}

.topTagsRows .tagsTop .ant-tag {
  background: #555092 !important;
  border-color: #555092 !important;
  color: #fff !important;
  font-weight: 500;
}

.topTagsRows .tagsTop .ant-tag .anticon-close {
  color: #fff;
}

.cnclBtn {
  color: #3d3d3d !important;
  font-weight: 700 !important;
  border-color: #f54838 !important;
  border-radius: 24px !important;
  width: 15% !important;
  height: 46px !important;
}

.wAuto {
  width: auto !important;
}

.topTagsRows .ant-select-arrow {
  color: #000 !important;
}

.profileModal .ant-modal-content {
  border-radius: 15px;
}

.profileModal .ant-modal-content .ant-modal-body {
  padding: 30px 50px;
}

.profileModal .ant-modal-content .ant-modal-body h4 {
  font-size: 22px;
  font-weight: bold;
}

.profileModal .profileModalBody .jobRqstHeading {
  border-bottom: 1px solid #c1c1c1;
}

.profileModal .ant-modal-content .ant-modal-body .customLabel h4 {
  font-size: 16px;
  font-weight: 500;
}

.profileModal .ant-modal-content .ant-modal-close {
  top: 2%;
  right: 6%;
}

.profileModal .ant-modal-content .ant-modal-close .ant-modal-close-x {
  font-size: 24px;
  font-weight: bold;
  color: #3d3d3d;
}

.packageModal .ant-modal-content {
  border-radius: 15px;
}

.packageModal .ant-modal-content .ant-modal-close {
  top: 1%;
  right: 1%;
}

.packageModal .ant-modal-content .ant-modal-close .ant-modal-close-x {
  font-size: 24px;
  font-weight: bold;
  color: #3d3d3d;
}

.packageModal .jobRqstHeading {
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 2rem;
}

.packageModal .ant-modal-content .ant-modal-body {
  padding: 25px;
}

.packageModal .packageCard {
  border-radius: 15px !important;
  height: 100%;
  margin-bottom: 1rem;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}

.packageModal .packageCard .ant-card-body {
  padding: 15px;
  height: 100%;
}

.pckgCheck .ant-checkbox-inner {
  border-radius: 50% !important;
}

.brdrCard {
  border-radius: 15px !important;
  height: 100% !important;
}

.brdrCard p {
  font-weight: 500;
  color: #3d3d3d !important;
}

.brdrCard .ant-card-body {
  height: 100% !important;
  display: flex;
}

.brdrCard .ant-card-body .packageRelevent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100% !important;
}

.ant-checkbox-checked::after {
  border: 0 !important;
}

/* .chatSidebar {
  border-right: 1px solid #f2f2f2;
  height: 75vh;
  max-height: 75vh;
} */
.chatSidebar {
  border-right: 1px solid #f2f2f2;
  height: 75vh;
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.chatSearch {
  word-break: break-all;
}

.ant-input-focused,
.ant-input:focus,
.ant-input:active {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.chatSearch,
.termsSection {
  /* padding: 15px; */
  padding: 6px 15px;
  border-bottom: 1px solid #f2f2f2;
}

.chatSideHead h4 {
  margin-bottom: 0 !important;
}

.chatSearchInput input,
.chatSearchInput .ant-input-search-button,
.chatMain .conversationSec {
  border: 0;
}

.chatSearchInput .ant-input-search-button {
  color: #3d3d3d !important;
}

.chatItem:last-child {
  border-bottom: 0;
}

.chatItem .nameSec h4 {
  font-size: 14px;
  font-weight: 500;
}

.chatItem .nameSec p,
.chatTime .greyText {
  font-size: 12px !important;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 9.375rem;
}

.chatItem {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  word-break: break-all;
}

.chatItem:hover,
.chatItem:first-child {
  background: #f2f2f2;
}

.chatMain .ant-comment-content-detail {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
}

.chatMain .ant-comment-content-detail p {
  width: auto;
  font-size: 12px;
}

.chatMain .receiver .ant-comment-content-detail p {
  background: #f2f2f2;
  padding: 10px;
  border-radius: 15px;
  border-top-left-radius: 0;
  margin-left: 1rem;
}

.chatMain .sender .ant-comment-content-detail p {
  background: #ffeeec;
  padding: 10px;
  border-radius: 15px;
  border-top-right-radius: 0;
  margin-right: 1rem;
}

.chatMain .conversationBox .ant-comment.sender .ant-comment-inner,
.chatMain .conversationBox .ant-comment.sender .ant-comment-content-detail {
  flex-direction: row-reverse;
}

/* .chatMain .conversationBox .ant-comment.receiver .ant-comment-inner,
.chatMain .conversationBox .ant-comment.receiver .ant-comment-content-detail:last-child{
  /* margin-bottom: 1rem; */

.chatMain .conversationBox {
  padding: 15px;
}

.chatListItems {
  height: 567px;
  overflow-y: auto;
}

.chatMain .conversationBox .ant-comment.receiver .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}

.chatMain .termsSection .jobRqstHeading h4 {
  font-size: 14px !important;
  margin-bottom: 0;
  font-weight: 500 !important;
}

.newMessage {
  position: relative;
}

.newMessage p {
  position: relative;
  z-index: 11;
  margin-bottom: 0;
}

.newMessage p span {
  background: #fff;
}

.newMessage p:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  left: 0;
  top: 0.7rem;
  z-index: -1;
}

.proposedBtn {
  border: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.proposedBtn.declinBtn {
  color: #f51500 !important;
}

.proposedBtn.sendBtn {
  color: #00711e !important;
}

/***** Notification Dropdown *****/
.dropdownContent {
  min-width: 21.875rem;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #f2f2f2;
  position: relative;
  top: 1.5rem;
  max-width: 21.875rem;
}

.notificationTitle {
  padding: 15px 25px;
  border-bottom: 1px solid #c1c1c1;
}

.notificationTitle h4 {
  margin-bottom: 0 !important;
  font-weight: 900 !important;
}

.notificationBody {
  padding: 0px 25px;
  min-height: 300px;
  height: 300px;
}

.notificationList {
  padding: 15px 0;
  border-bottom: 1px solid #f2f2f2;
}

.notificationList p {
  width: 100%;
  font-weight: 500;
  margin-bottom: 0 !important;
  overflow: hidden;
  word-break: break-word;
}

.name {
  font-weight: 700;
}

.Links {
  position: absolute;
  z-index: 999;
  text-align: right;
  width: 95%;
  padding: 25px;
  color: #fff;
}

.Links a {
  color: #fff !important;
  font-size: 16px;
  margin: 10px;
}

.Links a:hover {
  color: rgb(241, 211, 211);
}

.jobListTable table thead th {
  background: transparent;
  color: #3d3d3d;
  font-weight: 600;
}

.jobListTable table thead th:before {
  display: none;
}

.jobListTable table tbody td {
  color: #3d3d3d;
  font-weight: 500;
}

.customSearch .ant-input {
  border-radius: 15px !important;
  width: 100% !important;
  height: 53px !important;
  padding: 0 30px !important;
}

.customSearch .ant-input-group-addon {
  background-color: transparent;
  left: -5ch !important;
}

.customSearch .ant-input-group-addon button {
  border: 0;
  background: transparent;
  box-shadow: none;
}

.cstmrName h4 {
  color: #3d3d3d !important;
  margin-bottom: 0 !important;
  font-size: 16px !important;
}

.cstmrName p {
  font-size: 12px !important;
}

.brdrBtm {
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 20px;
}

.billingBottom {
  padding: 20px;
  background: #f2f2f2;
  margin: 15px 0;
}

.billingCancel h4 {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #3d3d3d !important;
}

.billingCancel p {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #3d3d3d !important;
  margin-bottom: 5px !important;
}

.mShow {
  display: none;
}

.custom-image-size .view-img-section {
  display: flex !important;
  justify-content: center !important;
}

.custom-image-size .view-img-section .ant-avatar {
  border-radius: 0 !important;
}

.custom-image-size .view-img-section .ant-avatar img {
  min-height: 15rem;
  max-height: 15rem;
  object-fit: contain !important;
}

.avatarSize {
  width: 100% !important;
  height: 100% !important;
  margin-top: 1rem !important;
}

.customFormItem .ant-form-item-control-input-content {
  text-align: right;
}

.customWidth {
  width: 52% !important;
}

.uploadInput {
  z-index: 11;
  position: relative;
  left: 0.5rem;
  opacity: 0;
}

.uploadInput + div {
  position: relative;
  right: 2.5rem;
}

.uploadCustom {
  display: flex;
}

.uploadedImg {
  width: 104px;
  height: 104px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: 1px solid #fafafa;
  margin-right: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 5px;
}

.uploadedImg .uploadFile {
  height: 95px;
}

.uploadedImg .uploadFooter {
  height: 22px;
}

.cstmFormControl {
  height: auto !important;
  padding: 0 5px !important;
  border-radius: 0 !important;
  font-size: 12px !important;
  width: 100%;
}

.w-20 .formcontrol {
  width: 20% !important;
}

.custom-bottom-text {
  font-size: 14px;
  margin-top: 15px;
  color: #a8abb7;
}

.custom-bio-padding {
  padding: 4px 10px 0 10px;
}

.update-bio {
  margin-top: 15px;
  margin-bottom: 15px;
}

.editBtn ms-4 {
  margin-left: 1rem !important;
}

/* .siderLayout ul li:nth-child(5) {
  margin-left: 5px !important;
}

.siderLayout ul li:nth-child(6) {
  margin-left: 5px !important;
} */

.custom-view-package-row {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
}

.custom-view-package-row col-md-6 {
  width: 50%;
  display: flex !important;
  justify-content: flex-end !important;
}

.custom-rightsection-package {
  display: flex !important;
  justify-content: flex-end !important;
}

.input-box-custom {
  width: 10rem !important;
  float: right !important;
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
}

.custom-package-input {
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
  /* width: 100% !important; */
  float: right !important;
  max-width: 160px;
  min-width: 160px;
}

.input-box-price {
  width: 77% !important;
  float: right !important;
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
}

.currency-wrap {
  position: relative;
}

.currency-code {
  position: absolute;
  left: 7rem;
  top: 5px;
  opacity: 1;
  z-index: 99;
}

.custom-rightsection-package .custom-revision .ant-form-item-has-success,
.successWidth .ant-form-item-has-success {
  width: 32%;
}

.HireModal .ant-modal-content {
  border-radius: 0.8rem !important;
}

.hire-content-row {
  width: 100%;
}

.hire-content-row h5 {
  margin: 0 !important;
  font-weight: 800;
}

.big-checkbox {
  width: 30px;
  height: 30px;
}

.custom-package-card {
  border-radius: 15px !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  margin-right: 1rem !important;
  margin-left: 1rem !important;
  width: 350px !important;
}

.custom-action-btn-row {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.custom-action-btn-row button {
  width: 150px !important;
  margin-right: 1rem;
}

.thumbnail .ant-image-img {
  width: 100% !important;
  max-width: 630px !important;
  height: 100% !important;
  max-height: 280px !important;
}

.thumbnail {
  height: 300px;
  text-align: center;
  padding: 10px;
}

.thumbnail .ant-image {
  height: 300px !important;
  width: 310px !important;
  border-radius: 1.2rem !important;
}

.thumbnail .ant-image img {
  border-radius: 1.2rem !important;
}

.HireModal .ant-modal-close-x {
  margin-right: 1rem !important;
  font-size: 25px !important;
  font-weight: 900 !important;
  color: black !important;
}

.job-title {
  margin-left: 10px !important;
}

.custom-rightsection-package .ant-form-item .ant-form-item-has-success {
  width: 32% !important;
}

.v {
  margin-top: 10px;
}

.custom-offer-btn {
  background-color: #10054b !important;
  padding: 10px;
  border-radius: 25px;
  border: 2px solid #f54838 !important;
  color: white !important;
}

.custom-offer-btn:hover {
  background-color: transparent !important;
  color: #f54838 !important;
}

.cstmFormControl {
  height: auto !important;
  padding: 0 5px !important;
  border-radius: 0 !important;
  font-size: 12px !important;
  width: 100%;
}

.w-20 .formcontrol {
  width: 20% !important;
}

.custom-height .ant-image-img {
  max-height: 400px !important;
}

.custom-update-image {
  border: 1px solid #f54838 !important;
  border-radius: 50px !important;
  background: #f54838 !important;
  font-weight: 600 !important;
  color: #fff !important;
  height: 48px !important;
  padding: 10px 25px !important;
  margin-right: 5px !important;
  transition: all 0.3s ease-in-out;
  margin-top: 20px !important;
  width: 200px !important;
}

.custom-update-image:hover {
  color: #f54838 !important;
  background: transparent !important;
  border: 1px solid #f54838 !important;
}

.package-image-edit {
  width: 100%;
}

.custom-add-req-btn {
  width: 200px !important;
}

.service-form {
  border-bottom: 0 !important;
}

.content-writing {
  display: none;
}

.translation {
  display: none;
}

.reset-service-btn {
  width: 20px;
  bottom: 2rem;
  height: 20px;
  background: #f54838 !important;
  color: #f54838 !important;
  margin-bottom: 20px !important;
}

.test .ant-form-item {
  margin-top: 1rem;
}

.custom-formItemHead {
  margin-bottom: 2px !important;
}

.btnTopRow .hire-Btn {
  border: 1px solid green !important;
  background: green !important;
  border-radius: 18px !important;
  color: #fff !important;
  font-weight: 600 !important;
  width: 100px !important;
  transition: all 0.2s ease;
  margin-right: 0.6rem;
}

.btnTopRow .message-btn {
  border: 1px solid #f54838 !important;
  background: #f54838 !important;
  border-radius: 18px !important;
  color: #fff !important;
  font-weight: 600 !important;
  transition: all 0.2s ease;
  padding: 7px 15px;
}

.btnTopRow .hire-Btn:hover {
  color: green !important;
  background: transparent !important;
}

.btnTopRow .message-btn:hover {
  color: #f54838 !important;
  background: transparent !important;
  transform: scale(1.07) !important;
}

.text-end input[type="radio"] {
  width: 2em;
  height: 2em;
  margin-top: -15px !important;
  accent-color: #f54838 !important;
}

.text-end input[type="radio"]:active {
  color: #f54838 !important;
  background: #f54838 !important;
}

.revision-btn {
  background: #f0f0f0 !important;
  color: black !important;
  border: 1px solid #f54838 !important;
  margin-right: 10px !important;
}

.enter-package-title {
  font-weight: 700 !important;
}

.custom-margin {
  margin-bottom: 10px;
}

.custom-button-section {
  display: flex;
  justify-content: flex-end;
}

.edit-package-btn,
.delete-package-btn {
  border: 1px solid black !important;
  background: white !important;
  height: 30px;
  width: 30px !important;
  text-align: center;
  border-radius: 50% !important;
  margin-right: 20px;
  padding: 7px;
  display: flex;
  color: black;
}

.custom-delete-package-btn {
  border: transparent !important;
  cursor: pointer;
  text-align: center;
  border-radius: 50% !important;
  padding: 7px;
  display: flex;
}

.media .d-flex {
  flex: 0 0 auto;
  width: 100%;
}

.media-body {
  width: 100%;
  padding-left: 30px;
  overflow: hidden;
}

.edit-package-btn svg:hover {
  color: #f54838;
}

.delete-package-btn svg:hover {
  color: #f54838;
}

.packagesList .ant-card-body {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.packagesList .ant-image-img {
  height: 230px !important;
  width: 280px !important;
  background-size: contain;
  object-fit: coverz;
}

.packageTitleRow {
  width: 100%;
  display: flex;
}

.packageTitleRow .pckgTtile {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.custom-pckg-header {
  display: unset !important;
}

.PasswordModal .ant-modal-content {
  border-radius: 15px !important;
}

.PasswordModal .customLabel {
  margin-bottom: 0.5rem;
}

.custom-viewpckg-inner-header {
  display: block !important;
}

.custom-text-bio {
  padding: 10px;
}

.customTableWhiteSpace table tbody tr td:last-child {
  white-space: nowrap;
}

.login-input {
  padding: 10px !important;
}

.custom-view-package-row .ant-select-selector {
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
}

.custom-offer-send-btn {
  transition: 0.2s ease;
}

.custom-offer-send-btn:hover {
  background: transparent !important;
  border: 1px solid #f54838 !important;
  color: #f54838 !important;
}

.custom-form-padding {
  padding: 11px !important;
}

.customSelect .ant-select-arrow .anticon {
  color: black !important;
  font-weight: 900;
}

.ant-select-dropdown {
  border-radius: 5px !important;
}

.custom-select-padding {
  padding: 6px !important;
}

.portTitle {
  display: flex;
  text-align: left;
  margin-left: 0.5rem;
  font-size: 16px !important;
  text-transform: uppercase;
  font-weight: 500;
  word-break: break-all;
  justify-content: space-between;
}

.portTitle a {
  color: black !important;
  padding: 5px !important;
}

.portBox {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 150px !important;
  border-radius: 5px !important;
  margin-left: 10px !important;
  background-color: #f2f2f2;
  margin-bottom: 1.5rem;
  max-width: 200px;
  height: fit-content;
}

.portBox .subPortbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portImg {
  max-height: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important;
  width: 150px !important;
  padding: 5px auto;
  border-radius: 5px !important;
  height: 150px !important;
}

.rightSection {
  position: sticky !important;
  top: 9rem;
}

.rightSection .customerCard {
  border-radius: 15px;
  border: 1px solid #d0d0d0 !important;
}

.formItemBtns {
  right: 0 !important;
  display: flex;
  justify-content: flex-end;
}

.custom-flex-package {
  justify-content: flex-start !important;
}

.custom-hire-title {
  font-size: 22px !important;
  font-weight: 600 !important;
}

.other-pckg-image {
  height: 220px !important;
}

.custom-update-pckg-title {
  padding: 14px !important;
  margin-top: 5px !important;
}

.edit-pckg-image-row {
  max-height: 700px !important;
}

.edit-pckg-image-row .ant-image-img {
  max-height: 500px !important;
  max-width: 500px !important;
}

.custom-language-padding .ant-select-selector input {
  padding-top: 15px !important;
}

.custom-input-padding-modal {
  padding: 12px !important;
}

.custom-input-padding-modal:focus-visible {
  outline: none;
  border: none;
}

.custom-modal-btn-padding {
  padding: 9px !important;
  height: auto !important;
}

.PasswordModal .ant-modal-body {
  padding: 40px !important;
}

.PasswordModal .ant-modal-close-x {
  margin-top: 1.3rem;
  margin-right: 1.2rem;
  color: black !important;
  font-size: 20px !important;
}

.cstm-hire-color {
  color: #f54838 !important;
}

.custom-class-flex {
  display: block !important;
}

.custom-image-size .ant-image {
  height: 200px !important;
  width: 300px !important;
}

.custom-image-size .ant-image img {
  height: 200px !important;
}

.cstm-pckg-height-width .ant-image .ant-image-img {
  max-height: 500px !important;
  max-width: 600px !important;
  border-radius: 15px !important;
}

.disabled-btn {
  border-radius: 18px !important;
  font-weight: 600 !important;
  width: 100px !important;
  background-color: #a8abb7 !important;
  color: white !important;
}

.cutom-btn-mrgn {
  margin-left: 0.6rem !important;
}

.add-portfolio-img {
  height: 80px !important;
  width: 95px !important;
  border-radius: 2px !important;
}

.package-delete-swal .swal-text {
  text-align: center !important;
}

.ant-form input[type="file"] {
  display: block;
  width: 100% !important;
  height: 100% !important;
}

.custom-ant-cell {
  width: 30% !important;
}

.package-description-custom {
  overflow: hidden !important;
  /* word-break: break-all !important; */
}

.dangerClass {
  color: #f54838 !important;
}
.successClass {
  color: green !important;
}

.showPointer {
  cursor: pointer;
}

.custom-image-class .img {
  width: 20px !important;
  height: 20px !important;
}

.ctm-pkg-content .ant-image .ant-image-img {
  width: 100% !important;
  height: 400px !important;
  border-radius: 15px;
}

.custom-port-img {
  padding: 5px auto;
  border-radius: 5px !important;
  max-width: 230px !important;
  max-height: 230px !important;
}

.other-pckg-image {
  max-width: 250px !important;
  max-height: 210px !important;
  overflow: hidden;
  width: 250px !important;
  height: 210px !important;
  background-size: contain;
}

.tablecustom-row {
  margin-top: 20px !important;
}

.portObject {
  width: 180px !important;
  height: 180px !important;
  background-size: contain;
}

.ant-form-item .ant-upload.ant-upload-drag {
  border-radius: 10px !important;
  margin-top: 10px !important;
  background: #f2f2f2 !important;
}

.pckg-title {
  font-size: 18px !important;
  font-weight: 700;
  overflow: hidden;
  word-break: break-all;
}

.table-header-custom {
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: 0.5rem 0;
}

.custom-portbox-color {
  background: #f2f2f2;
}

.portfolio-section {
  padding-top: 5px;
}

.custom-View-all {
  position: relative !important;
}

.cstm-table-layout {
  table-layout: fixed !important;
}

.cstm-table-layout tr td {
  vertical-align: initial !important;
}

.custom-table-data {
  word-break: break-all !important;
  overflow: hidden !important;
  padding-right: 20px !important;
}

.bold-text {
  font-weight: bold !important;
}

.custom-add-rates-input {
  padding: 12px !important;
}

.custm-seeBtn {
  padding: 10px !important;
  height: auto !important;
}

.cstmformItemBtns {
  right: 0 !important;
  justify-content: flex-end;
}

.custm-pckgHeader {
  display: block !important;
}

.cstm-select-rates .ant-select-selector {
  padding: 12px !important;
  height: auto !important;
}

.cstm-select-rates-input {
  padding: 12px !important;
  height: auto !important;
}

.portfolio-title {
  color: black !important;
  font-weight: bold !important;
}

.portfolio-title::placeholder {
  color: black !important;
  font-weight: 400 !important;
}

.custom-edit-image-class {
  max-width: 190px !important;
  max-height: 190px !important;
  height: 190px !important;
  width: 190px !important;
  object-fit: fill !important;
}

.custm-padding {
  padding-left: 10px !important;
}

.req-reply-section {
  padding-left: 30px !important;
}

.requirement-reply {
  border: 1px solid #cecece;
  border-radius: 5px !important;
  padding: 10px !important;
}

.req-reply-section-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.req-reply-btn {
  width: 100px !important;
  padding: 5px !important;
}

.remove-req-sec {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.remove-req-btn {
  background: #f2f2f2 !important;
  color: black;
  border-radius: 25px;
  font-size: 14px;
  padding: 2px 10px;
  border: none !important;
}

.primaryBtn:hover {
  color: #f54838;
  background: white;
  border: 1px solid #f54838;
  font-weight: 500;
}

.requirementAnswerSection {
  display: flex !important;
  width: auto !important;
}

.req-ans-wrapper {
  width: 100% !important;
}

.custom-req-answer {
  width: 100% !important;
  padding: 10px !important;
  border-radius: 7px !important;
  border: 1px solid #cecece !important;
  margin-top: 5px !important;
}

.custom-req-answer:focus {
  border: 1px solid #cecece !important;
  outline: none !important;
}

.add-answer-section {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.custom-postfolio-image-height {
  height: 125px;
  object-fit: cover;
  width: 10rem;
}

.custom-accept-Btn {
  border: 1px solid green !important;
  border-radius: 50px;
  background: green !important;
  font-weight: 600;
  color: #fff;
  height: 48px;
  padding: 10px 25px;
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
}

.custom-accept-Btn:hover {
  border: 1px solid green;
  color: green !important;
}

.modal-bottom-section {
  display: flex !important;
  justify-content: flex-end !important;
  padding-top: 1rem;
}

.req-ans-section {
  display: flex;
  justify-content: flex-start;
  padding-left: 2rem;
}

.bold-text-req {
  font-weight: 700;
  font-size: 20px;
}

.modal-middle-section {
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
}

.bold-text-req-solution {
  font-weight: 500;
  font-size: 16px;
}

.share-exp-header {
  border-bottom: 1px solid #cecece;
}

.share-exp-header span {
  font-size: 12px !important;
  color: #a8abb7 !important;
}

.share-exp-star {
  color: #f54838 !important;
  font-size: 24px !important;
}

.share-exp-btn {
  border: none !important;
  color: #f54838 !important;
  background: transparent !important;
  font-size: 14px !important;
  font-weight: 500;
}

.profileavatarSize {
  width: 100% !important;
  height: 100% !important;
}

.custom-cancel-title {
  font-weight: 700 !important;
}

.custom-cancel-rason-input {
  width: 40rem;
}

.packageModal .packageModalBody .packageCard .pckgContent .ant-image img {
  height: 270px !important;
  width: 320px !important;
  border-radius: 15px;
  object-fit: fill;
  margin-bottom: 5px;
}

.top-border {
  border-top: 1px solid #f2f2f2 !important;
}

.periodSec {
  display: flex;
  flex-direction: column !important;
}

.periodSec .starting {
  display: flex;
  justify-content: space-between;
}

.periodSec .ending {
  display: flex;
  justify-content: space-between;
}

.periodSec .budget {
  display: flex;
  justify-content: space-between;
}

.pckgBtn {
  all: unset !important;
  padding: 0 1rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.pckgBtn span {
  color: #000;
  font-weight: 500;
}

.pckgBtn:hover span {
  color: #f54838;
}

.custom-profile-name {
  padding-left: 10px;
}

.custom-underline {
  border-bottom: 1px solid #f2f2f2;
}

.custom-profile-name-free {
  padding-left: 15px;
}

.custom-profile-name-free a {
  text-transform: capitalize;
}

.border-btm {
  padding-bottom: 1rem;
  border-bottom: 1px solid #a8abb7;
}

.custom-category {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.custom-cursor {
  cursor: pointer;
}

.hoverBtn:hover {
  color: #f54838 !important;
  background: transparent !important;
  border: 1px solid #f54838 !important;
}

.customPadding {
  padding: 14px 10px !important;
}

.wordsBtm {
  padding-top: 1.1rem;
}

.buttonsRow Button {
  cursor: pointer !important;
}

.customRow .modalBtnSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.customRow .modalBtnSection .modalBtn {
  color: #f54838;
  border: 1px solid #f54838;
  border-radius: 25px;
  padding: 2px 30px;
}

.customFonts {
  font-size: 15px !important;
}

.DisputeModal .ant-modal-content {
  border-radius: 15px !important;
}

.DisputeModal .ant-modal-body {
  padding: 40px !important;
}

.disputeRow {
  display: flex;
  padding-top: 1rem;
}

.disputeRow .leftSec {
  display: flex;
  justify-content: flex-end;
}

.disputeRow Button {
  cursor: pointer !important;
}

.disputeRow .rightSec {
  display: flex;
  justify-content: flex-start;
}

.custDisputeRow {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.custDisputeRow Button {
  cursor: pointer !important;
}

.badge-block {
  background-color: #f54838;
  color: white;
  text-decoration: none;
  padding: 2px 5px;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  top: -15px;
  left: -10px;
}

.customDrag .ant-form-item-control .ant-form-item-explain-error {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  text-align: end !important;
}

.customModalprofile {
  top: 8rem !important;
}

.custom-width-input {
  width: 30% !important;
}

.custom-width-input-price {
  width: 100%;
}

.custom-width-input-price .formcontrol {
  width: 100% !important;
  max-width: 100px !important;
}

.rqurmntContentBox {
  padding-left: 0.5rem;
  margin-top: -1rem;
  margin-bottom: 0.5rem;
}

.rqurmntContentBox p {
  margin-bottom: 0;
}

.customuploadCustom {
  justify-content: flex-end !important;
  margin-top: 0.5rem;
}

.chatMain .customConversation .sender .ant-comment-content-detail p {
  background: #ffeeec;
  padding: 10px;
  border-radius: 15px;
  border-top-right-radius: 0;
  margin-right: 1rem;
  word-break: break-all;
  overflow: hidden;
}

.chatMain .customConversation .receiver .ant-comment-content-detail p {
  background: #f2f2f2;
  padding: 10px;
  border-radius: 15px;
  border-top-left-radius: 0;
  margin-left: 1rem;
  word-break: break-all;
  overflow: hidden;
}

.chatMain .customConversation .leftSkeleton {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.chatMain .customConversation .rightSkeleton {
  margin-bottom: 1rem;
}

.chatMain .customConversation .rightSkeleton .ant-skeleton {
  display: flex;
  flex-direction: row-reverse;
}

.chatMain
  .customConversation
  .rightSkeleton
  .ant-skeleton
  .ant-skeleton-header {
  padding-right: 0;
  padding-left: 1rem;
}

.chatMain
  .customConversation
  .rightSkeleton
  .ant-skeleton
  .ant-skeleton-content
  .ant-skeleton-title {
  margin-left: auto;
}

.chatSidebar .customLeftSkeleton {
  padding: 1rem;
}

.packageReject {
  all: unset;
  color: #ff0000;
  padding: 5px 10px;
}

.packageAccept {
  all: unset;
  color: #4caf50;
  padding: 5px 10px;
}

.googleLoginSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.googleLoginSection .ant-radio-group {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}

/* .googleLoginSection .ant-radio-group span{
  padding-left: 1rem;
} */
.googleLoginSection button {
  width: fit-content;
}

.countrySelection {
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.countrySelection .ant-select-arrow {
  display: none;
}

.countrySelection .ant-select {
  border: 1px solid #cecece;
  border-radius: 7px;
}

.cnvrsFtr .cnvrsFtrInput {
  resize: none;
  padding: 1rem 1rem;
  height: auto;
  min-height: 3rem;
}

.messageDot {
  position: relative;
}

.messageDot span {
  font-size: 4rem;
  position: absolute;
  color: #f54838;
  top: -5.8rem;
  right: -0.5rem;
}

.chatTime {
  padding-left: 4px;
}

.chatCounter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.chatCounter span {
  color: #fff;
  background: #f54838;
  border: 1px solid #f54838;
  border-radius: 50%;
  padding: 0 3px;
  height: 1.2rem;
  text-align: center;
  line-height: 16px;
}

.ftrFormControl .ant-upload-picture-card-wrapper {
  width: unset !important;
}

.ftrFormControl
  .ant-upload-picture-card-wrapper
  .ant-upload-select-picture-card {
  border: none !important;
  height: auto !important;
  background-color: transparent !important;
  width: 2rem;
  padding-left: 1rem;
  margin-top: 0.7rem;
}

.ftrFormControl
  .ant-upload-picture-card-wrapper
  .ant-upload-select-picture-card
  .anticon-paper-clip {
  font-size: 1.2rem;
}

.loaderImageSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.custom-filters .ant-select-selector .ant-select-selection-placeholder {
  color: black;
  font-weight: 500;
  padding: 10px !important;
}

.custom-language-padding .ant-select-selector .ant-select-selection-item {
  color: black !important;
  font-weight: 500 !important;
}

.ant-image-preview-img {
  width: 31.25rem;
  max-width: 31.25rem;
}

.attachmentWrapper .ant-image img {
  max-width: 8rem;
  width: 8rem;
  min-width: 8rem;
  max-height: 7rem;
  min-height: 7rem;
  border-radius: 7px;
}

.descSection {
  max-width: 38rem;
}

.descSection h5 {
  font-weight: 400 !important;
}

.titleSection {
  max-width: 38rem;
}

.titleSection h5 {
  font-weight: 400 !important;
}

.CountrySelectionBox .ant-select-selection-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.CountrySelectionBox .formControl {
  padding: 8px !important;
}

.CountrySelectionBox .ant-select-selector {
  background: #f2f2f2 !important;
  border-radius: 15px !important;
  border: 0 !important;
}

.googleRadio .ant-radio-inner {
  border-color: #f54838 !important;
}

.facebookRadio .ant-radio-inner {
  border-color: #f54838 !important;
}

.googleRadio .ant-radio-inner::after {
  background-color: #f54838;
}

.facebookRadio .ant-radio-inner {
  border-color: #f54838 !important;
}

.CountrySelectionBox .formControl {
  text-align: left !important;
}

.CountrySelectionBox .ant-form-item-control-input-content {
  text-align: left !important;
}

.colSecond .detailsSec {
  overflow: hidden;
  width: 10.5rem;
}

.ans-row {
  justify-content: space-between;
}

.cstm-margin-top {
  margin-top: 0.4rem;
}

.notificationMsg .redtext {
  color: #f54838 !important;
}

.notificationMsg .blacktext {
  color: black !important;
}

.attachmentmsg {
  display: flex;
  max-height: 22rem;
  max-width: 40rem;
  min-width: 22rem;
  flex-direction: column;
  margin-bottom: 1rem;
}

.imgFooterPreview {
  display: flex;
  position: absolute;
  bottom: 2rem;
  color: white;
}

.imgFooterPreview span {
  font-size: 3rem;
}

.downloadWrapper {
  background: black;
  width: 2rem;
  padding: 0.5rem;
  position: absolute;
  top: 0.6rem;
  right: 1.6rem;
  opacity: 0.5;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.withdrawlRow {
  display: flex;
  justify-content: space-between;
}

.ModlaHeader {
  color: darkgray !important;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

.modalDivider {
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  margin: 5px 0;
}

.customDivider {
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  margin: 15px 0;
}

.customMargin {
  margin-top: 5px;
}

.routingRow {
  display: flex;
  justify-content: space-between;
}

.PaymentCard {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  padding: 1rem;
  border-radius: 3px;
  width: 100%;
}

.paymentLeftCard {
  border-right: 1px solid #cecece;
  display: flex !important;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.paymentLeftCard .anticon {
  color: white;
  font-size: 40px;
  background-color: orange;
  border-radius: 50px;
  border: 1px solid orange;
}

.paymentRightCard {
  padding-left: 1rem;
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
}

.paymentForm .ant-form-item {
  width: 100%;
}

.paymentForm .ant-input {
  border-radius: 4px;
}

.paymentForm .ant-select-selector {
  border-radius: 5px !important;
}

.customModalRow .img-fluid {
  max-height: 22rem;
  max-width: 40rem;
  min-height: 22rem;
  min-width: 40rem;
}

.packageModalBody .packageCardBody {
  height: 100%;
}

.packageModalBody .packageRelevent {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.packageModalBody .packageCard .pckgContent {
  height: 300px;
}

.customImageClass {
  height: 15rem;
  min-width: 22rem;
  border-radius: 5px;
}

.EmojiPickerReact {
  position: absolute !important;
  bottom: 3rem;
}

.dateWrapper {
  background: #f54838;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 105px;
  border-radius: 10px;
  vertical-align: middle;
  position: absolute;
}

.messageDot {
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
}

.centeraligned {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  position: relative;
}

#sizer {
  width: 100% !important;
  height: 100% !important;
}

.emoji-picker-react {
  position: absolute !important;
  bottom: 7rem;
}

.uploadedPortTitle input {
  height: 30px !important;
  width: 150px !important;
  border-radius: 0 5px 0 5px !important;
  padding: 1rem !important;
  font-weight: bold;
}

.UploadedPortBox {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  height: fit-content;
  width: 150px;
  min-width: 150px;
}

.chatFileDownloadButton {
  background: none !important;
  border: none !important;
}

.DownloadIconChat {
  border-radius: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  bottom: 8rem;
  right: 9rem !important;
  border: none !important;
  background: transparent !important;
  font-size: 25px !important;
  color: black !important;
  background: white !important;
  opacity: 0.5;
  padding: 1rem;
}

.conversationBox {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.mypackagesDelet .ant-card-body {
  padding: 7px !important;
}

.searchCentre input {
  direction: ltr;
  vertical-align: middle;
  margin-top: 3px !important;
  position: absolute;
}

.googleIcon {
  background: linear-gradient(-120deg, #4285f4, #34a853, #fbbc05, #ea4335);
  font-size: 40px;
}

.facebookLogo {
  font-size: 40px;
}

.talentMenu .ant-menu-submenu .ant-menu-submenu-inline {
  margin-right: 6px !important;
}

.custom-width-input-price .ant-form-item-explain.ant-form-item-explain-error {
  display: flex !important;
  justify-content: flex-end !important;
}

.talentMenu .ant-menu-submenu-title .ant-image {
  margin-right: 7px !important;
  margin-left: 3px;
}

.custom-popularity-padding .ant-select-selector .ant-select-selection-item {
  padding-left: 10px !important;
  font-weight: 500;
}

.customSearchRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.customSearchRow .seeProfileSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.customBottomMargin {
  margin-bottom: 0 !important;
}

.customtcRate {
  padding-top: 5px;
}

.payment-edit-icon {
  border: none !important;
  margin-right: 1rem;
}

.custom-freelancer-language .ant-select-selection-search {
  padding-top: 12px;
}

.msg-download-icon-free {
  border-radius: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  top: 1rem !important;
  right: 0.5rem !important;
  border: none !important;
  background: transparent !important;
  font-size: 25px !important;
  color: black !important;
  background: white !important;
  opacity: 0.5;
}

.msg-download-conversation-free {
  border-radius: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  top: 1rem !important;
  right: 1rem !important;
  border: none !important;
  background: transparent !important;
  font-size: 25px !important;
  color: black !important;
  background: white !important;
  opacity: 0.5;
}

.msg-download-icon-cust {
  border-radius: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  top: 2rem !important;
  right: 1.4rem;
  color: black !important;
  border: none !important;
  font-size: 20px !important;
  background: white;
  opacity: 0.8;
}

.custom-header-font {
  font-size: 13px !important;
}

.normalPckgImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.normalPckgImage .ant-image img {
  max-width: 38rem;
  width: 38rem;
  min-width: 38rem;
  max-height: 20rem;
  min-height: 20rem;
  border-radius: 7px;
}

.custom-width-input-price .cstmHireModalWidth {
  max-width: 158px !important;
  border: none !important;
}

.custom-width-input-price .cstmInputBackground {
  background: #f2f2f2 !important;
  border: none !important;
}

.custom-width-input-price .cstmHireModalWidth:hover {
  border: none !important;
}

.signInLink {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  font-weight: bold;
}

.signInLink a {
  color: #f54838 !important;
  font-weight: bold;
  padding-left: 5px;
}

.signInLink a:hover {
  color: #f54838 !important;
  text-decoration: underline !important;
}

.withdrawalStatus {
  color: #f54838 !important;
  font-weight: bold;
}

.customMethodName {
  color: black !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}

.cardBorder {
  width: 100%;
}

.cardBorder .flex-end {
  display: flex;
  justify-content: flex-end;
}

.customMedia-body .pckgHeader {
  flex-direction: column;
}

.earningsMenu .ant-menu-submenu-title .ant-image {
  margin-left: 3px;
}

.newReqBtn {
  background: #f54838 !important;
  color: white !important;
  padding: 0.3rem 1rem;
  border: none !important;
  border-radius: 25px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  height: fit-content !important;
}

.searchTalentRow {
  width: 100%;
  /* margin: 0; */
  padding: 0;
  display: flex;
  align-items: flex-start;
}

.searchTalentRow .tagCol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  padding: 0;
  margin: 0;
}

.tcInner .tcHeaderRow {
  display: flex;
  align-items: flex-start;
}

.tcInner .tcHeaderRow .tcLeftCol {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.tcInner .tcHeaderRow .tcLeftCol .nameSec h4 {
  margin-bottom: 0;
  line-height: 20px;
}

/* .tcInner .tcHeaderRow .tcLeftCol .tcImg {
  margin-top: -8px !important;
} */

.tcInner .tcHeaderRow .tcRightCol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding-right: 1.3rem;
}

.seeProfileBtn {
  background: #f54838 !important;
  border-color: #f54838 !important;
  border-radius: 25px !important;
  color: #fff !important;
  font-weight: 600 !important;
  padding: 0.3rem 1rem !important;
}

.tcInner .tcHeaderRow .tcRightCol .tcRate {
  padding-top: 4px;
}

.customChatSideHead {
  padding: 0.93rem !important;
}

.customEditProfileBox {
  border: none !important;
  padding: 0 !important;
}

.reqWithdrawalNavigation {
  display: flex;
}

.reqWithdrawalNavigation a h5 {
  color: #f54838 !important;
  text-decoration: underline;
}

.tnDataTable .ant-table .ant-table-content .buttonSec h1 {
  font-size: 14px !important;
}

.modalDetailBtn {
  background: #f54838 !important;
  border-color: #f54838 !important;
  border-radius: 25px !important;
  color: #fff !important;
  font-weight: 600 !important;
  height: auto !important;
  padding: 5px 1rem !important;
}

.cstmtnDataTable .ant-table table tr th {
  align-items: center;
  text-align: center;
}

.cstmtnDataTable .ant-table table tr td {
  align-items: center;
  text-align: center;
}

.cstmtnDataTable .ant-table table tr th:nth-child(3) {
  align-items: center;
  text-align: center;
}

.cstmtnDataTable .ant-table table tr td:nth-child(3) {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.earningsData .sendToYou .methodName {
  font-size: 20px !important;
  font-weight: bold !important;
}

.earningsData .paymentRightCol {
  display: flex;
  justify-content: flex-end;
}

.TalentRightCol {
  display: flex;
  justify-content: flex-end;
}

.TalentLeftCol {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.TalentLeftCol h4 {
  margin-bottom: 0 !important;
}

.redPriceText {
  font-size: 1rem;
  color: #f54838;
}

.profileBtnSection {
  display: flex;
  justify-content: flex-end;
}

.swal-title {
  font-size: 20px !important;
}

.noPckgText {
  color: #a8a8a8 !important;
  /* opacity: 0.6; */
  padding: 0 3rem;
}

.NoPackageSection {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.profileSaveBtn {
  background: #f54838 !important;
  border-color: #f54838 !important;
  border-radius: 25px !important;
  color: #fff !important;
  font-weight: 600 !important;
  width: 8rem !important;
  display: flex;
  height: 2.8rem !important;
  justify-content: center;
  align-items: center !important;
  text-align: center !important;
}

.capitalizeName {
  text-transform: capitalize;
}

.ant-card-body:focus-visible {
  outline: none !important;
  box-shadow: none !important;
  font-size: 20px !important;
}

.tcImg .ant-scroll-number {
  position: absolute !important;
  top: 0.8rem !important;
  right: 0.4rem;
}

.requestedWithdrawalBtn {
  background: #f2f2f2 !important;
  color: black !important;
  border: none !important;
  border-radius: 25px !important;
  font-weight: 600 !important;
  height: auto !important;
  padding: 5px 3.2rem !important;
}

.earningsData .sendToYou .ant-checkbox-inner {
  width: 25px !important;
  height: 25px !important;
  border-radius: 25px !important;
}

.earningsData .sendToYou .ant-checkbox-inner::after {
  left: 27.5% !important;
}

.stripeFormWrapper .stripBottomWrapper {
  display: flex;
  justify-content: flex-start;
}

.paidBtn {
  padding: 5px 4.6rem !important;
}

.no-padding {
  padding-left: 0 !important;
}

.translationmodalbtn {
  border-color: unset !important;
  /* outline: 2px solid #f54838 !important; */
  cursor: pointer;
}

.Contentmodalbtn {
  border-color: unset !important;
  /* outline: 2px solid #1890ff !important; */
  cursor: pointer;
}

.pdfWrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.warningMsgSection {
  display: flex;
  justify-content: flex-end;
}

.warningMsgSection p {
  color: #f54838;
  font-weight: 500;
}

.custom-pckg-content .ant-image {
  width: 100%;
}

.conversationMsg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.customPckgTitle {
  font-weight: 600 !important;
  text-transform: capitalize;
}

.invoiceBtn {
  display: flex;
  align-items: center;
  text-align: center;
}

.InvoiceModal {
  width: 800px !important;
}

.InvoiceModal .ant-modal-content {
  border-radius: 15px !important;
  width: 50rem;
}

.InvoiceModal .ant-modal-body {
  padding: 2.5rem !important;
}

.InvoiceModal .ant-modal-close {
  top: 1.5rem !important;
  right: 1.2rem !important;
  font-weight: bold !important;
}

.InvoiceModal .modalnner .invoiceNumberRow {
  display: flex;
}

.InvoiceModal .modalnner .invoiceNumberRow h4 {
  font-size: 14px;
  font-weight: 700;
}

.InvoiceModal .modalnner .invoiceNumberRow h5 {
  margin-top: 0 !important;
  padding-left: 0.3rem;
  font-weight: 300;
  font-size: 14px;
}

.InvoiceModal .modalnner .invoiceDetailHeader {
  background-color: #eeeeee;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.InvoiceModal .modalnner .invoiceDetailHeader h4 {
  font-weight: 700;
  font-size: 16px;
}

.InvoiceModal .modalnner .invoiceDetailHeader h5 {
  font-weight: 500;
  font-size: 14px;
}

.InvoiceModal .modalnner .invoiceDetailInner {
  padding: 1rem;
}

.InvoiceModal .modalnner .invoiceDetailInner h5 {
  font-weight: 500;
  font-size: 14px;
  margin-top: 0 !important;
}

.InvoiceModal .modalnner .packageDetailInner {
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-top: 0.3rem !important;
}

.InvoiceModal .modalnner .modalFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.InvoiceModal .modalnner .modalFooter p {
  color: #f54838;
}

.InvoiceModal .billToWrapper h4 {
  font-size: 14px;
  font-weight: 700;
}

.InvoiceModal
  .InvoiceTable
  .ant-table-wrapper
  .ant-table
  .ant-table-content
  table {
  text-align: center !important;
}

.InvoiceModal
  .InvoiceTable
  .ant-table-wrapper
  .ant-table
  .ant-table-content
  table
  tr
  th {
  font-weight: 700;
  text-align: center !important;
}

.InvoiceModal .addressWrapper p {
  margin-bottom: 5px !important;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
}

.InvoiceModal .JobDetailsHeading h5 {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.InvoiceModal .modalheader {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.InvoiceModal .modalFooter button {
  padding: 2px 16px;
  border: none;
  border-radius: 25px;
  background-color: #f54838;
  color: white;
  font-size: 14px;
  font-weight: 700;
  min-width: 160px;
}

.invoiceRow {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.invoiceRow .cancelBtn .cancelBtnText {
  vertical-align: middle;
}

.InvoiceModal .InvoiceTable .ant-table-wrapper {
  width: 100%;
}

.add-new-req-row {
  display: flex;
  justify-content: space-between;
}

.customCancelBtn {
  cursor: default !important;
}

.reportsWrapper .tableWrapper .reportsTable thead tr th {
  text-align: center;
  padding: 16px 2px;
}

.reportsWrapper .tableWrapper .reportsTable tbody tr td {
  text-align: center;
  padding: 16px 2px;
}

.reportJob {
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
}

.reportsWrapper .contentArea .filterSection .leftSection {
  padding: 1rem;
}

.reportsWrapper .contentArea .filterSection .leftSection input {
  padding: 0.6rem;
}

.reportsWrapper .contentArea .filterSection .leftSection button {
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.reportsWrapper .contentArea .filterSection .rightSectionReports {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.reportsWrapper .contentArea .filterSection .rightSectionReports .ant-select {
  width: 12rem !important;
}

.reportsWrapper
  .contentArea
  .filterSection
  .rightSectionReports
  .ant-select
  .ant-select-selector {
  padding: 0.4rem 0.5rem;
  height: auto;
  border-radius: 5px;
}

.newSupportBtn {
  background: #f54838;
  border: none;
  border-radius: 25px;
  color: #fff;
  font-weight: 600;
  width: 100px;
  height: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newSupportBtn:hover {
  background: white;
  color: #f54838;
  border: 1px solid #f54838;
}

.SupportList .HeaderSection {
  display: flex;
  justify-content: space-between;
}

.detailBtn {
  background: #f54838 !important;
  border-radius: 25px !important;
  color: #fff !important;
  font-weight: 600 !important;
  height: auto;
  padding: 0.2rem 1.4rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f54838 !important;
}

.detailBtn:hover {
  background: transparent !important;
  color: #f54838 !important;
}

.payment-pckg-title {
  font-size: 18px;
  color: black;
  font-weight: 500;
}

.pendingPaymentWrapper
  .ant-table-wrapper
  .ant-table
  .ant-table-thead
  .ant-table-cell {
  align-items: center;
  text-align: center;
}

.pendingPaymentWrapper
  .ant-table-wrapper
  .ant-table
  .ant-table-tbody
  .ant-table-cell {
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.pendingPaymentWrapper
  .ant-table-wrapper
  .ant-table
  .ant-table-tbody
  .ant-table-cell
  p {
  margin-bottom: 0;
}

.CompletePaymentWrapper .checkoutBody .jobinfo {
  display: flex;
}

.CompletePaymentWrapper .checkoutBody .jobinfoBudget {
  display: flex;
}

.CompletePaymentWrapper .checkoutBody .jobinfo p {
  font-weight: 500;
  font-size: 18px;
}

.CompletePaymentWrapper .checkoutBody .jobinfoBudget p {
  font-weight: 500;
  font-size: 18px;
}

.CompletePaymentWrapper .checkoutBody .jobinfo span {
  font-weight: 500;
  font-size: 18px;
  padding-left: 0.8rem;
}

.CompletePaymentWrapper .checkoutBody .jobinfoBudget span {
  font-weight: 900;
  font-size: 18px;
  padding-left: 0.8rem;
  color: #f54838;
}

.CompletePaymentWrapper .cardheader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.CompletePaymentWrapper .cardheader .anticon {
  font-size: 30px;
}

.completedRaiseDispute {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.detailBtnPaid {
  background: #cecece !important;
  border-color: #cecece !important;
  border-radius: 25px !important;
  color: #000 !important;
  font-weight: 500 !important;
  height: auto !important;
  padding: 5px 2.5rem !important;
}

.rqurmntWrapper {
  display: flex;
  align-items: baseline;
}

.InvoiceModal .logoImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.InvoiceModal .modalFooter {
  justify-content: space-between !important;
}

.InvoiceModal .modalFooter p {
  padding-top: 0.8rem;
}
.greenBtn {
  border: 1px solid green !important;
  border-radius: 50px !important;
  background: green !important;
  font-weight: 600 !important;
  color: #fff !important;
  height: 48px !important;
  padding: 10px 20px !important;
  transition: all 0.3s ease-in-out;
}
.greenBtn:hover {
  background: transparent !important;
  color: green !important;
}
.pckgViewBtn {
  border: 1px solid #f54838 !important;
  border-radius: 50px !important;
  background: transparent !important;
  font-weight: 600 !important;
  color: #f54838 !important;
  height: 34px !important;
  padding: 4px 15px !important;
  margin-right: 5px !important;
  transition: all 0.3s ease-in-out;
}
.pckgViewBtn:hover {
  background: #f54838 !important;
  color: #fff !important;
}
.save-req-btn {
  padding: 10px 30px !important;
}
.greenHireBtn {
  margin-right: 0.5rem;
  padding: 10px 30px !important;
}
.invoiceDownloadBtn {
  border: 1px solid #f54838 !important;
  border-radius: 50px !important;
  background: #f54838 !important;
  font-weight: 600 !important;
  color: #fff !important;
  padding: 1px 20px !important;
  margin-right: 5px !important;
  transition: all 0.3s ease-in-out;
}
.customGreenBtn {
  margin-right: 1rem;
  width: 15%;
}
.viewPckgBtn {
  border: 1px solid #f54838 !important;
  border-radius: 50px !important;
  background: #fff !important;
  font-weight: 600 !important;
  color: #f54838 !important;
  padding: 1px 30px !important;
  margin-right: 5px !important;
}
.sendMsgBtn {
  border: 1px solid green !important;
  border-radius: 50px !important;
  background: green !important;
  font-weight: 600 !important;
  color: #fff !important;
  padding: 0px 15px !important;
  transition: all 0.3s ease-in-out;
  margin-left: 0.4rem;
}
.disabledInvoiceBtn {
  border: 1px solid #cecece !important;
  border-radius: 50px !important;
  background: #cecece !important;
  font-weight: 600 !important;
  color: #fff !important;
  padding: 1px 45px !important;
  margin-right: 5px !important;
  transition: all 0.3s ease-in-out;
}
.marginRightBtn {
  margin-right: 0.8rem;
}
.customDetailBtn {
  padding: 5px 2.4rem !important;
}
.detailBtnDisabled {
  background: #cecece !important;
  border-color: #cecece !important;
  border-radius: 25px !important;
  color: #000 !important;
  font-weight: 500 !important;
  padding: 5px 3.4rem !important;
}
.noRequestSection {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}
.alignCenterRow {
  align-items: baseline;
}

/***** June 28, 2023 *****/
.customSider .ant-menu-item .ant-menu-item-icon,
.customSider .ant-menu-submenu .ant-menu-item-icon {
  font-size: 1.25rem;
  opacity: 0.6;
}

.customSider .ant-menu-item.ant-menu-item-selected .ant-menu-item-icon {
  opacity: 1;
}

.redStar {
  color: #f54838;
  padding-left: 5px;
}

/***** July 14, 2023 *****/
.swalButton {
  background: #f54838 !important;
  border-color: #f54838 !important;
  border-radius: 25px !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.swalButton:focus {
  box-shadow: none;
}

.contentArea
  .updateForm
  .jobRqstRow
  .packageSec
  .pckgInner
  .linksWrapper
  .linkPill {
  display: flex !important;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 0.5rem 0;
  border-radius: 25px;
  background: #f2f2f2;
}
.contentArea
  .updateForm
  .jobRqstRow
  .packageSec
  .pckgInner
  .linksWrapper
  .linkPill
  .linkSection {
  max-width: 600px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.contentArea
  .updateForm
  .jobRqstRow
  .packageSec
  .pckgInner
  .linksWrapper
  .linkPill
  .iconSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
}
.contentArea
  .updateForm
  .jobRqstRow
  .packageSec
  .pckgInner
  .linksWrapper
  .linkPill
  .iconSection
  svg {
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.contentArea
  .updateForm
  .jobRqstRow
  .packageSec
  .pckgInner
  .linksWrapper
  .linkPill
  .iconSection
  svg:hover {
  color: #ea4335;
}
