@media only screen and (min-width: 280px) and (max-width: 320px) {
    .rhLogo .ant-image {
        width: 100px !important;
    }

    .orText,
    .signUpForm .ant-form-item-label>label {
        font-size: 12px;
    }

    .socialLogInBtn,
    .formLink,
    .formBtm,
    .primaryBtn {
        font-size: 12px !important;
    }

    .tnCard .ant-card-body {
        padding: 15px !important;
    }

    .Links a {
        font-size: 12px;
        margin: 6px;
    }

    .topLeftSection .tlsInner .leftHeading {
        margin-top: 25px !important;
    }

    .topLeftSection .tlsInner .leftHeading h2 {
        font-size: 14px !important;
        line-height: 22px !important;
    }

    .wcCard .wcCardTitle h5 {
        font-size: 16px;
        font-weight: 600;
    }

    .wcCard .wcCardDesc p {
        font-size: 14px;
    }

    .wcCard .wcCardIcon .wcCardIconInner {
        width: 35px;
        height: 35px;
    }

    .ftrContent .ftrHeading h2 {
        font-size: 20px;
    }

    .ftrContentList ul:before {
        height: 61.4% !important;
        top: 13.5% !important;
    }

    .mt-10 {
        margin-top: 2rem !important;
    }

    .psrltvBtm {
        margin-bottom: 0 !important;
    }

    .psrltvBtm img {
        bottom: 0;
    }

    .posRltv img {
        top: 0;
    }

    .faqSection .faqInner .faqHeading h2 {
        font-size: 18px !important;
        line-height: 26px;
    }

    .faqSection .faqInner .faqcollapseSection .ant-collapse-header {
        font-size: 12px !important;
    }

    .faqSection .faqInner .faqcollapseSection .anticon-plus {
        font-size: 14px !important;
    }

    .nlsHeading {
        padding: 0 !important
    }

    .newsLetterSection .nlsInner {
        padding: 25px 0 !important;
    }

    .nlsInputSection .nlsBtn {
        width: 76% !important;
    }

    .tcHeading h4 {
        font-size: 16px !important;
    }

    .tcHeading p {
        font-size: 12px !important;
    }

    .pckgHeader h2 {
        font-size: 16px !important;
    }

    .description {
        font-size: 14px !important;
    }

    .btmSec {
        flex-wrap: wrap;
    }

    .dlvrytext .me-3 {
        margin-right: 1rem !important;
    }

    .tcProfile {
        flex-wrap: wrap;
    }

    .tcDetail {
        margin-top: 20px;
    }

    .tcTagsRow {
        margin-top: 10px;
    }

    .tagCol {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
    }

    .tagCol:last-child {
        margin: 10px 0;
    }

    .tagCol .seeBtn {
        width: 100%
    }

    .jobRqstHeading.dFlex {
        flex-wrap: wrap;
    }
}

@media only screen and (min-width: 360px) {

    .orText,
    .signUpForm .ant-form-item-label>label {
        font-size: 14px;
    }

    .socialLogInBtn {
        font-size: 14px !important;
    }

    /* .rhLogo .ant-image {
    width: 150px !important;
  } */
}

@media only screen and (max-width: 767px) {
    .ms-8 {
        margin-left: 0;
    }

    .topLeftSection .tlsInner .leftHeading {
        margin-right: 0;
    }

    .topLeftSection .tlsInner .leftHeading h2 {
        font-size: 16px;
        line-height: 30px;
        text-align: center;
    }

    .topLeftSection .tlsInner .leftHeading p {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
    }

    .tlsSection {
        text-align: center;
    }

    .tlsSection .tlsInput {
        width: 100%;
    }

    .tlsSection .tlsBtn {
        width: 97%;
        margin-top: 5px;
    }

    /* .colMob {
    margin-top: 10px;
  } */
    .dHide {
        display: none;
    }

    .mShow {
        display: flex;
        justify-content: space-between;
        padding: 15px 0
    }

    .logoSec .ant-image {
        width: 120px !important;
    }

    .Links {
        position: relative;
        color: #000;
        width: auto;
        padding: 0
    }

    .Links a {
        color: #000 !important;
    }

    .tlc2:before {
        left: 0;
    }

    .nlsInputSection .nlsInput {
        width: 100%;
    }

    .wcnSection .wcnInner {
        padding: 50px 0;
    }

    .wcnSection .wcnInner .wcnHeading h2,
    .faqSection .faqInner .faqHeading h2 {
        font-size: 22px;
        letter-spacing: 0;
    }

    .wcnSection .wcnInner .wcnHeading h4,
    .faqSection .faqInner .faqHeading h4 {
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
    }

    .wcCard {
        margin-bottom: 15px !important;
    }

    .ftrSection {
        margin: 0;
    }

    .ftrImage {
        display: none;
    }

    .ftrContent,
    .faqInner {
        padding: 50px 0 !important;
    }

    .ftrContentList ul:before {
        height: 57% !important;
        top: 18% !important;
    }

    .ftrContentList ul li:last-child {
        padding-bottom: 0;
    }

    .ftrContent .ftrHeading h2 {
        font-size: 26px;
    }

    .ftrContentList ul li h5 {
        font-size: 18px;
    }

    .ftrContentList ul li p {
        font-size: 14px;
        line-height: 24px;
    }

    .tlc1:before,
    .tlc2:before,
    .tlc3:before {
        display: none;
    }

    .faqSection .faqInner .faqcollapseSection .ant-collapse-header {
        font-size: 16px;
    }

    .newsLetterSection .nlsInner .nlsHeading h2 {
        font-size: 18px;
        line-height: 32px;
    }

    .nlsInputSection .nlsBtn {
        width: 84%;
        margin-top: 10px;
    }

    .tmlSection .tlContent p,
    .tmlSection .tlContent ul li {
        font-size: 14px;
        line-height: 24px;
    }

    .rhLogo .ant-image {
        top: 0;
    }

    .signUpCardSection {
        margin-top: -2rem;
        padding: 0 15px;
        padding-bottom: 1rem;
    }

    .tnCard .ant-card-body {
        padding: 25px;
    }

    .chatMain .sender .ant-comment-content-detail p {
        width: 100%;
    }

    .siderLayout {
        display: none;
    }

    .contentArea {
        margin-top: 20px;
        margin-left: 0;
    }

    .cardMargin {
        margin-bottom: 15px;
    }

    .colFourth .buttonSec button {
        margin-bottom: 10px !important;
    }

    .o1 {
        order: 1;
    }

    .o2 {
        order: 2
    }

    .mobSider .siderLayout {
        display: block;
        position: relative;
        top: 0;
        height: 100%;
    }

    .mobSider .ant-drawer-content-wrapper {
        width: 310px !important;
        box-shadow: none !important;
    }

    .mobSider .ant-drawer-mask {
        display: none !important;
    }

    .mobSider .ant-drawer-content-wrapper .ant-drawer-content {
        background-color: transparent !important;
    }

    .mobSider .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-close {
        top: 2rem !important;
        padding: 5px !important;
        color: #fff !important;
        background: #10054b;
        border-radius: 10px;
    }

    .packagesList .packageItems .media {
        flex-wrap: wrap;
    }

    /* .packagesList .packageItems .media .ant-image {
    margin-bottom: 15px;
  } */
    .packagesList .packageItems .media .me-3 {
        /* margin-right: 0 !important; */
        width: 100% !important
    }

    .packagesList .packageItems .media .me-3 .ant-image {
        height: 100% !important
    }

    .packagesList .packageItems .media .me-3 .ant-image,
    .packagesList .packageItems .media .me-3 img {
        width: 100% !important
    }

    .packagesList .packageItems .media .media-body {
        margin-top: 25px;
    }

    .packagesList .packageItems .media .media-body .btmSec {
        flex-wrap: wrap;
    }

    .pckgHeader h2 {
        font-size: 16px !important;
    }

    .addPackageFormSection {
        padding: 0 15px;
    }

    .viewTalent .talentCards .tcProfile,
    .viewTalent .talentCards .tcProfile .rightPart {
        flex-wrap: wrap;
    }

    .viewTalent .talentCards .tcProfile .rightPart .actionBtns {
        margin-top: 15px;
        width: 100%;
    }

    .viewTalent .talentCards .tcProfile .rightPart .actionBtns .btnTopRow {
        display: flex
    }

    .viewTalent .talentCards .tcProfile .rightPart .actionBtns .btnTopRow .hireBtn {
        width: 100%;
    }

    .tcTagsRow {
        flex-wrap: wrap;
    }

    .tcProfile {
        flex-wrap: wrap;
    }

    .tcDetail {
        margin-top: 20px;
    }

    .tcTagsRow {
        margin-top: 10px;
    }

    .tagCol {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
    }

    .tagCol:last-child {
        margin: 10px 0;
    }

    .tagCol .seeBtn {
        width: 100%
    }

    .filtersOption .filtersSlct .ant-select-selector {
        width: 100% !important;
    }

    .filtersOption .ant-select {
        margin-bottom: 15px;
        margin-right: 0px !important;
        width: 100% !important;
    }

    .welcomeImg .ant-image {
        width: 120px;
    }

    .profileCard .welcomeTextSec p {
        font-size: 22px;
        margin-bottom: 10px;
    }

    .profileCard .cardProfile .ant-card-body {
        padding: 24px;
    }

    .tnDataTable table tbody tr td:last-child {
        white-space: nowrap;
    }

    .cardFormControl {
        width: 100%;
    }

    .subTitle {
        width: 100%;
    }

    .cardBorder {
        border: 1px solid #c1c1c1;
        border-radius: 15px;
        margin: 0 0 20px !important;
        padding: 10px 5px;
        height: 100%;
    }

    .cardBorder .ant-col {
        margin: 5px 0;
    }

    .w-70 {
        width: 100% !important;
    }

    .formItemBtns {
        display: flex
    }

    .cnclBtn,
    .saveBtn {
        width: 100% !important;
    }

    .customerCard {
        margin-bottom: 15px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .ms-8 {
        margin-left: 40px;
    }

    .topLeftSection .tlsInner .logoSec {
        padding: 10px 0px;
    }

    .topLeftSection .tlsInner .leftHeading {
        margin-right: 0;
        margin-top: 20px !important;
    }

    .topLeftSection .tlsInner .leftHeading h2 {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .topLeftSection .tlsInner .leftHeading p {
        font-size: 14px;
        line-height: 24px;
    }

    .ftrImage {
        display: none;
    }

    .ftrContentList ul:before {
        height: 56.4%;
        top: 20.5%;
        /* height: 45.4%;
    top: 23.5%; */
    }

    .psrltvBtm img {
        bottom: 2rem
    }

    .posRltv img {
        top: 0;
    }

    .ftrSection {
        margin: 0;
    }

    .tlc1:before,
    .tlc3:before {
        left: -5rem;
    }

    .tlc2:before {
        right: -5rem;
    }

    .ftrContentList ul li:last-child {
        padding-bottom: 0;
    }

    .tmlSection .tlContent p,
    .tmlSection .tlContent ul li {
        font-size: 14px;
        line-height: 24px;
    }

    .faqSection .faqInner,
    .wcnSection .wcnInner,
    .ftrContent {
        padding: 50px 0;
    }

    .faqSection .faqInner .faqHeading h2,
    .wcnSection .wcnInner .wcnHeading h2 {
        font-size: 1.7rem;
        letter-spacing: 0;
    }

    .faqSection .faqInner .faqHeading h4,
    .wcnSection .wcnInner .wcnHeading h4 {
        width: 100%;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .newsLetterSection .nlsInner .nlsHeading h2 {
        font-size: 1.4rem;
        line-height: 40px;
    }

    .rhLogo .ant-image {
        top: 4rem;
    }

    .signUpCardSection {
        margin-top: -4rem;
    }

    .tnCard .ant-card-body {
        padding: 25px;
    }

    .tnBtnSec .ant-radio-group .ant-radio-wrapper {
        margin-right: 1rem !important;
    }

    .siderLayout {
        display: none;
    }

    .contentArea {
        margin-top: 20px;
        margin-left: 0;
    }

    .mobSider .siderLayout {
        display: block;
        position: relative;
        top: 0;
        height: 100%;
    }

    .mobSider .ant-drawer-content-wrapper {
        width: 310px !important;
        box-shadow: none !important;
    }

    .mobSider .ant-drawer-mask {
        display: none !important;
    }

    .mobSider .ant-drawer-content-wrapper .ant-drawer-content {
        background-color: transparent !important;
    }

    .mobSider .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-close {
        top: 2rem !important;
        padding: 5px !important;
        color: #fff !important;
        background: #10054b;
        border-radius: 10px;
    }

    .filtersOption .filtersSlct .ant-select-selector {
        width: 215px !important;
    }

    .tnDataTable table tbody tr td:last-child {
        white-space: nowrap;
    }

    .jobRqstList table {
        table-layout: fixed !important;
    }

    .resWidth {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .me-3 .ant-image {
        width: 100% !important;
        height: 100% !important
    }

    .packagesList .packageItems .media {
        flex-wrap: wrap;
    }

    .packagesList .packageItems .media .ant-image {
        margin-bottom: 15px;
    }

    .packageModalBody .packageCard .pckgContent {
        height: auto !important;
    }

    .HireModal .packageCard .hire-modal-pckg-content .ant-image img {
        width: 100% !important;
        height: auto !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .topLeftSection .tlsInner .leftHeading h2 {
        font-size: 28px;
        line-height: 36px;
    }

    .topLeftSection .tlsInner .leftHeading p,
    .wcCard .wcCardDesc p {
        font-size: 14px;
        line-height: 22px;
    }

    .tlsSection {
        margin-top: 1rem !important;
    }

    .signUpCardSection {
        margin-top: -6rem;
    }

    .topLeftSection .tlsInner .leftHeading {
        margin-top: 0 !important;
    }

    .wcnSection .wcnInner .wcnHeading h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .wcnSection .wcnInner .wcnHeading h4 {
        font-size: 16px;
        line-height: 24px;
    }

    .wcCard .wcCardTitle h5 {
        font-size: 18px;
    }

    .ftrImage {
        top: -10px;
        left: -45rem;
    }

    .ftrContent,
    .wcnSection .wcnInner,
    .faqSection .faqInner {
        padding: 50px 0;
    }

    .ftrImage .ant-image img {
        width: 70%;
        max-width: 100%;
    }

    .ftrContent .ftrHeading h2,
    .faqSection .faqInner .faqHeading h2,
    .newsLetterSection .nlsInner .nlsHeading h2 {
        font-size: 32px;
    }

    .ftrContentList ul li h5,
    .faqSection .faqInner .faqHeading h4 {
        font-size: 18px;
        line-height: 26px;
    }

    .ftrContentList ul:before {
        height: 55.4%;
        top: 15.5%;
    }

    .ftrContentList ul li p,
    .faqSection .faqInner .faqcollapseSection .ant-collapse-header {
        font-size: 16px;
    }

    .tlc1:before,
    .tlc3:before {
        left: -6.2rem;
    }

    .tlc2:before {
        right: -6.2rem
    }

    .ms-8 {
        margin-left: 55px;
    }

    .psrltvBtm img {
        bottom: 3rem;
    }

    .tmlSection .tlContent h5 {
        font-size: 18px;
    }

    .tmlSection .tlContent p,
    .tmlSection .tlContent ul li {
        font-size: 16px;
        line-height: 24px;
    }

    .posRltv img {
        top: 1rem;
    }

    .faqSection .faqInner .faqcollapseSection .ant-collapse-header {
        padding: 0px 0 5px !important;
    }

    .tnBtnSec .ant-radio-group .ant-radio-wrapper {
        margin-right: 1rem !important;
    }

    .filtersOption .filtersSlct .ant-select-selector {
        width: 200px !important;
    }

    .tcRate p {
        font-size: 14px !important;
    }

    .tcRate {
        margin-top: 3px;
    }

    .me-3 .ant-image {
        width: 100% !important;
        height: 100% !important
    }

    .packagesList .packageItems .media {
        flex-wrap: wrap;
    }

    .packagesList .packageItems .media .ant-image {
        margin-bottom: 15px;
    }

    .tnDataTable table tbody tr td:last-child {
        white-space: nowrap;
    }

    .jobRqstList table {
        table-layout: fixed !important;
    }

    .siderLayout .ant-menu-root {
        z-index: 99;
        height: 250px;
        overflow-x: auto;
    }

    .supportBtnSec {
        z-index: -1;
    }

    .supportBtnSec .ant-image {
        width: 35%
    }
}

@media (min-width: 1200px) and (max-width: 1359px) {
    .siderLayout ul {
        z-index: 99;
        /* height: 250px; */
        overflow-x: auto;
    }

    .supportBtnSec {
        z-index: -1;
    }

    .supportBtnSec .ant-image {
        width: 35%
    }

    .PasswordModal {
        top: 20rem !important;
    }


}

@media (min-width: 1201px) and (max-width: 1366px) {

    .tlc1:before,
    .tlc3:before {
        left: -7.2rem;
    }

    .tlc2:before {
        right: -7rem;
    }

    .topRightSection .ant-image img {
        width: 90%;
        margin-left: auto;
    }

    .tnBtnSec .ant-radio-group span {
        font-size: 13px !important;
    }

    .packageModal {
        margin-top: -2rem !important;
    }

    .packageModal .packageModalBody .packageCard .pckgContent {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .packageModal .packageModalBody .packageCard .pckgContent .ant-image img {
        height: 200px !important;
        width: fit-content !important;
    }

    .packageModal .packageModalBody .pckgHeader {
        padding: 0 10px;
    }

    .packageModal .packageModalBody .pckg-category {
        margin-top: 20px;
    }

    .packageModal .packageModalBody .pckgHeader h4 {
        font-size: 14px !important;
    }

    .packageModal .packageModalBody .pckgHeader h2 {
        font-size: 20px !important;
    }

    .packageModal .pckgInner {
        width: auto !important;
    }
}

@media (min-width: 1400px) and (max-width: 1600px) {
    .topLeftSection .tlsInner .leftHeading h2 {
        font-size: 42px;
        line-height: 54px;
    }

    .topLeftSection .tlsInner .leftHeading p {
        font-size: 22px;
        line-height: 40px;
    }

    .ftrImage {
        position: absolute;
        z-index: 99;
        top: -15px;
        left: -38rem;
    }

    .ftrImage .ant-image img {
        width: 74%;
    }

    .ftrContentList ul:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 46%;
        top: 23%;
        border: 1px solid #f2f2f2;
        border-right: 0;
    }

    .ftrL1:before,
    .ftrL2:before,
    .ftrL3:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        top: 8px;
        left: 10px;
        background: #f2f2f2;
    }

    .ftr2:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 1px;
        top: 12px;
        left: 0px;
        background: #f2f2f2;
    }

    .tlsSection .tlsInput {
        width: 330px;
        background: #f2f2f2;
        border: 1px solid #f2f2f2;
        padding: 20px;
        border-radius: 6px;
        font-family: "DM Sans", sans-serif !important;
        margin-right: 5px;
    }

    .tlsSection .tlsBtn {
        all: unset;
        padding: 20px 10px;
        background: #f54838;
        border: 1px solid #f54838;
        color: #fff;
        font-family: "DM Sans", sans-serif !important;
        font-weight: 500;
        border-radius: 6px;
        cursor: pointer;
    }

    .topRightSection .ant-image img {
        width: 90%;
        margin-left: auto;
    }

    .dashboard-hire-modal {
        position: absolute !important;
        left: 0;
        right: 0;
        top: 30% !important;
        width: 700px !important;
        height: auto;
    }

    .custom-dashboard-hire-modal {
        position: absolute !important;
        left: 0;
        right: 0;
        top: 10% !important;
        width: 700px !important;
        height: auto;
    }

}

@media only screen and (min-width: 1600px) {
    .ftrImage {
        left: -32rem;
    }

    .dashboard-hire-modal {
        position: absolute !important;
        left: 0;
        right: 0;
        width: 700px !important;
        height: auto;
    }

    .custom-dashboard-hire-modal {
        position: absolute !important;
        left: 0;
        right: 0;
        top: 10% !important;
        width: 700px !important;
        height: auto;
    }
}

@media (min-width: 1920px) {
    .topLeftSection .tlsInner .leftHeading h2 {
        font-size: 42px;
        line-height: 54px;
    }

    .topLeftSection .tlsInner .leftHeading p {
        font-size: 18px;
        line-height: 36px;
    }

    .topRightSection .ant-image img {
        width: 90%;
        margin-left: auto;
    }

    .ftrImage .ant-image img {
        width: 74%;
    }

    .ftrImage {
        position: absolute;
        z-index: 99;
        top: -15px;
        left: -22rem;
    }

    .ftrContentList ul:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 46%;
        top: 23%;
        border: 1px solid #f2f2f2;
        border-right: 0;
    }

    .ftrL1:before,
    .ftrL2:before,
    .ftrL3:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        top: 8px;
        left: 10px;
        background: #f2f2f2;
    }

    .ftr2:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 1px;
        top: 12px;
        left: 0px;
        background: #f2f2f2;
    }

    .tlsSection .tlsInput {
        width: 330px;
    }

    .tlsSection .tlsBtn {
        all: unset;
        padding: 20px 8px;
        background: #f54838;
        border: 1px solid #f54838;
        color: #fff;
        font-family: "DM Sans", sans-serif !important;
        font-weight: 500;
        border-radius: 6px;
        cursor: pointer;
    }

    .signUpCardSection {
        margin-top: -18rem !important;
    }
}

@media only screen and (max-height: 800px) {
    .siderLayout ul li {
        margin-bottom: 18px !important;
    }

}

@media only screen and (max-height: 735px) {
    .siderLayout ul li {
        margin-bottom: 8px !important;
    }

    .supportBtnSec .ant-image .ant-image-img {
        height: 130px;
    }

    .supportBtnSec .supportBtn .sprtBtn {
        padding: 8px 29px;
    }
}

@media only screen and (max-height: 660px) {
    .siderLayout ul li {
        margin-bottom: 2px !important;
    }

    .supportBtnSec .ant-image .ant-image-img {
        height: 100px;
    }

    .supportBtnSec .supportBtn .sprtBtn {
        padding: 6px 25px;
    }
}

@media (min-width: 1000px) and (max-width: 1226px) {
    .cstm-table-layout tr .tcTags .ant-tag {
        font-size: 9px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1500px) {
    .filtersOption .filtersSlct .ant-select-selector {
        /* border-radius: 15px !important;
  width: 195px !important; */
        height: 53px !important;
    }
}

@media (min-width:1200px) and (max-width:1250px) {
    .tnBtnSec .ant-radio-group span {
        font-size: 12px !important;
    }
}

@media (min-width: 800px) and (max-width: 1000px) {
    .cstm-table-layout tr .tcTags .ant-tag {
        font-size: 8px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .jobID p {
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    .jobDue p {
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    .jobStart p {
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    /* .PasswordModal {
margin-top: -200px !important;
} */
    .cstm-table-layout tr .tcTags .ant-tag {
        font-size: 9px !important;
    }

    .avatarSize {
        height: 50% !important;
        padding: 2px 10px !important;
    }

    .custom-cardFormControl {
        margin-top: 25px !important;
    }

    .colFourth .buttonSec .acceptBtn {
        font-size: 10px !important;
        padding: 2px 20px !important;
        height: 38px !important;
    }

    .dashboard-hire-modal {
        position: absolute !important;
        left: 0;
        right: 0;
        top: 10% !important;
        width: 700px !important;
        height: auto;
    }

    .dashboard-hire-modal .acceptBtn {
        font-size: 10px;
        padding: 2px 20px !important;
        height: 38px !important;
    }

    .dashboard-hire-modal .bold-text-req {
        font-size: 16px;
    }

    .dashboard-hire-modal .bold-text-req-solution {
        font-size: 14px;
    }

    .custom-dashboard-hire-modal {
        position: absolute !important;
        left: 0;
        right: 0;
        top: 10% !important;
        width: 700px !important;
        height: auto;
    }

    .custom-dashboard-hire-modal .acceptBtn {
        font-size: 10px;
        padding: 2px 20px !important;
        height: 38px !important;
    }

    .custom-dashboard-hire-modal .bold-text-req {
        font-size: 16px;
    }

    .custom-dashboard-hire-modal .bold-text-req-solution {
        font-size: 14px;
    }
}

@media (min-width: 700px) and (max-width: 1000px) {
    .customImageClass {
        height: 10rem;
        min-width: 12rem;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .customImageClass {
        height: 10rem;
        min-width: 14rem;
    }
}

@media (min-width: 1200px) and (max-width:1400px) {
    .customImageClass {
        height: 10rem;
        min-width: 17rem;
    }
}

@media (min-width: 1400px) and (max-width:1600px) {
    .customImageClass {
        min-height: 10rem;
        min-width: 20rem;
    }
}

@media (min-width: 1600px) and (max-width:1800px) {
    .customImageClass {
        height: 12rem;
        min-width: 24rem;
    }
}

@media (min-width: 1800px) and (max-width:2000px) {
    .customImageClass {
        height: 14rem;
        min-width: 22rem;
    }

    .customImageClassPackage {
        height: 14rem;
        min-width: 18rem;
    }
}

@media (max-height:750px) {
    .conversationSec .downloadCsv .seeBtn {
        width: 140px !important;
        padding: 5px;
    }

}

@media (min-height:700px) and (max-height:768px) {
    .conversationInner .customConversationBox {
        height: 44vh;
    }

}

@media (min-height:800px) {
    .conversationInner .conversationBox {
        height: 48vh;
    }

    .conversationInner .customConversationBox {
        height: 50vh;
    }
}

@media (min-height:800px) {
    .conversationInner .conversationBoxFreelancer {}

}